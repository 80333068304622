////////////////////////////////////////////////////////////////////////////////////// DEFINE //////////////////////////////////////////////////
/*react / components*/
import React from 'react'
import {Helmet} from "react-helmet";
import Modal from 'react-responsive-modal'
import BeerModal from './beermodal'
import AwesomeSlider from 'react-awesome-slider';
import { withRouter } from "react-router-dom";
import ButterToast, { Cinnamon,POS_TOP, POS_CENTER } from 'butter-toast';
import Legend from './legend'


/*styles*/
import 'react-awesome-slider/dist/styles.css';
import '../../css/modal.css'


/*functions*/
import { Icontypebar } from '../../functions/ifelse';
import { distance } from '../../functions/ifelse';
import { baseurl } from '../../functions/ifelse.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMapMarkerAlt, faStar, faPlusCircle, faClock, faUserClock, faQuestion} from '@fortawesome/free-solid-svg-icons'
import {faLaptop} from '@fortawesome/free-solid-svg-icons'
import {faPhoneAlt} from '@fortawesome/free-solid-svg-icons'
import {faArrowsAltH} from '@fortawesome/free-solid-svg-icons'
import {faEuroSign} from '@fortawesome/free-solid-svg-icons'
import {faUser} from '@fortawesome/free-solid-svg-icons'

////////////////////////////////////////////////////////////////////////////////////// FIN DEFINE //////////////////////////////////////////////////
let bg = {
    overlay: {
      //lo que queda feura del modal
    },
    modal:{
        background: "rgb(245, 247, 249)",
        width:'100%'
    },
    closeButton:{
        width:'20px',
        top:'5px',
        rigth:'1px'
    }
  };
let lang = null;
let beerPreSelect = null;
let primerrender = true;
let contentToast,contentToastEdit,titleToast,titleToastEdit,titleToastHappy, contentToastHappy = "";
let id = 0;
class ModalPlace extends React.Component {
    
    constructor(props){
        super(props);
        lang=this.props.lang;
        console.log(this.props);
        this.state = {beerSelected: null};
    }

    componentDidUpdate(){
        if(primerrender && beerPreSelect != null){
            console.log ("seleccccionado click en "+beerPreSelect);
            this.selectBeer(beerPreSelect);
            primerrender = false;
        }
    }

    

    updateParent(){
        this.props.toggleShowModalCallback()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // Cada vez que props.email cambia, actualiza el estado.
        
        if (nextProps.beer !== this.props.beer) {
          this.setState({
            beerSelected: nextProps.beer
          });
        }
      }

    selectBeer=(beer)=>{
        console.log("click beer in modal"+beer.name);
        this.setState((state) => {
            // Importante: lee `state` en vez de `this.state` al actualizar.
            return {beerSelected:beer}
        });
    };

    showToast() {
        ButterToast.dismissAll();
        ButterToast.raise({
            content: <Cinnamon.Crisp scheme={Cinnamon.Crisp.SCHEME_BLUE}
                content={() => contentToast}
                title={titleToast}/>
        });
    }

    showToastHappy() {
        ButterToast.dismissAll();
        ButterToast.raise({
            content: <Cinnamon.Crisp scheme={Cinnamon.Crisp.SCHEME_BLUE}
                content={() => contentToastHappy}
                title={titleToastHappy}/>
        });
    }

    showToastEdit() {
        ButterToast.dismissAll();
        ButterToast.raise({
            timeout: 3000,
            content: <Cinnamon.Crisp scheme={Cinnamon.Crisp.SCHEME_BLUE}
                content={() => contentToastEdit}
                title={titleToastEdit}/>
        });
          
    }

    showToastLegend() {
        ButterToast.dismissAll();
        ButterToast.raise({
            timeout: 6000,
            content: <Cinnamon.Crisp scheme={Cinnamon.Crisp.SCHEME_BLUE}
            content={<Legend lang={lang}/>}
            title={<div className="container toasttitle">{lang.leyenda}</div>}/>
            });
    }

    LinkToEdit(){
        this.props.history.replace("/edit/"+ id);
    }

    closeToast(){
        ButterToast.dismissAll();
    }
  

    render(){      


        if(this.props.place != null){
            console.log("render modalplace");
            var place = this.props.place;
            //puede que no haya beer seleccionada
            id = place.placeID;

            var leng = lang.lang;
            var urladd = "/add/"+id+"/"+ this.props.place.name;
            var urlphoto = "addphoto?placeID="+id+"&name="+ this.props.place.name+"&lang="+leng;

            titleToast = lang.openingHours;
            contentToast = place.openingHours;
            titleToastEdit = lang.confirmEdit;
            titleToastHappy = lang.happyHour;
            contentToastHappy = place.happyHour;
            contentToastEdit = <div><button onClick={() => this.closeToast()} type="alert">{lang.cancel}</button><button onClick={() => this.LinkToEdit()} type="succes">{lang.continue}</button></div>;
            
            var type = place.type;
            var typebar = Icontypebar(type);
            const title= this.props.place.name;
            const numRows = this.props.place.beers.length;
            
            let distanciahtml = "";
            let distancia = null;
            distancia = distance(place,this.props.loc);
            if(distancia != null){
                distanciahtml = (<div className="textmodal col-6">
                                <span><FontAwesomeIcon icon={faArrowsAltH} alt=""/> {distancia}</span>
                </div> );
            }


            let pricehtml = [];
            for (let i = 0; i < place.priceLevel; i++) {
                pricehtml.push(<FontAwesomeIcon key={"euro"+i} icon={faEuroSign} size="xs" color="rgba(0, 0, 0, 0.54)"/>);
            }
            

            let phonehtml = <span className="linktextUnder" onClick={() =>this.showToastEdit()}>{lang.add_phone}</span>;
            if(place.phone != null && place.phone.length > 0)
                phonehtml = <span><FontAwesomeIcon icon={faPhoneAlt} alt=""/> {place.phone}</span>;

            let webhtml =<span className="linktextUnder" onClick={() =>this.showToastEdit()}>{lang.add_web}</span>;
            if(place.web != null && place.web.length > 0)
                webhtml = <span><FontAwesomeIcon icon={faLaptop} alt=""/> {place.phone}</span>;
            
            let addresshtml = <span className="linktextUnder" onClick={() =>window.open("https://maps.google.com/?q="+place.lat+","+place.lng)}>{lang.view_gm}</span>;
            if(place.address != null && place.address.length > 0)
                addresshtml = <span className="linktext" onClick={() =>window.open("https://maps.google.com/?q="+place.lat+","+place.lng)}><FontAwesomeIcon icon={faMapMarkerAlt} alt=""/> {place.address}</span>

            let openingHours = <span className="linktextUnder" onClick={() =>this.showToastEdit()}>{lang.add_openingHours}</span>;
            if(place.openingHours.length > 0)
                openingHours = <span className="linktext" onClick={this.showToast.bind(this)}><FontAwesomeIcon icon={faClock} alt=""/> {lang.show_openingHours}</span>
            
            let happyHour = <span className="linktextUnder" onClick={() =>this.showToastEdit()}>{lang.add_happyHour}</span>;
            if(place.happyHour.length > 0)
                happyHour = <span className="linktext" onClick={this.showToastHappy.bind(this)}><FontAwesomeIcon icon={faUserClock} alt=""/> {lang.show_happyHours}</span>
            
            
                var placeimages = place.images;
            var numimages = place.images.length;

            let i =0; 
            let data = [];
            placeimages.map((image) => {
                data.push(<div key={"images"+i} data-src={baseurl+image.image} data-slug={i}/>);
                i++;
            })

            let user = place.username;
            let userID = place.userID;

            var colorhex = "#FBBC04";

            var beerSelected = this.state.beerSelected;
            //Si solo tiene una cerveza la seleccionamos por defecto
            if(place.beers.length == 1 && beerSelected == null){
                beerSelected = place.beers[0];
            }

            var meta;
            if(beerSelected != null){
                meta = <Helmet>
                    <title>{"Worldbeermap - "+place.name + " - "+ beerSelected.name}</title>
                    <meta name="description" content={place.name + " - "+ beerSelected.name + " - "+ beerSelected.description} />
                    <meta name="keywords" content={lang.meta_keys + ","+ beerSelected.name} />
                </Helmet> ; 
            }
            else{
                meta = <Helmet>
                    <title>{"Worldbeermap - "+place.name}</title>
                    <meta name="description" content={place.name + place.description} />
                    <meta name="keywords" content={lang.meta_keys + ","+ place.name} />
                </Helmet> ; 

            }
           
            return (
                
                
                <Modal open={this.props.abierto} onClose={this.updateParent.bind(this)} center styles={bg}>
                    {meta}
                    <div className="container">
                    
                        <div className="row">

                            <div className="col-12 col-lg-6">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="titlegoogle">{title}</div>
                                    </div>   
                                    <div className="col-12">
                                        <table>
                                            <tr>
                                                <td className="ratinggoogle">{place.gRating}</td>
                                                <td className="starsmodal"><span><FontAwesomeIcon color={place.gRating > 0 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                    <span><FontAwesomeIcon color={place.gRating > 1 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                    <span><FontAwesomeIcon color={place.gRating > 2 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                    <span><FontAwesomeIcon color={place.gRating > 3 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                    <span><FontAwesomeIcon color={place.gRating > 4 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                </td>
                                                <td className="ratinggoogle">·</td>
                                                <td className="ratinggoogle">{type}</td>
                                                <td className="ratinggoogle">·</td>
                                                <td className="ratinggoogle starsmodal">{pricehtml}</td>
                                                <td className="ratinggoogle">{lang.price_level[place.priceLevel]}</td>
                                            </tr>
                                        </table>
                                    </div> 
                                    
                                    
                                    <div className="row col-12 margintop w-100">
                                        <div className="col-6 textmodal">
                                            {phonehtml}
                                        </div>   
                                        <div className="col-6 textmodal">
                                            {webhtml}
                                        </div>   
                                    </div>

                                    <div className="row textmodal col-12 w-100">      
                                        <div className="col-6 textmodal">
                                            {openingHours}
                                        </div>
                                        <div className="col-6 textmodal">
                                            {happyHour}
                                        </div>
                                    </div>
                                    
                                    <div className="textmodal col-12">
                                        {addresshtml}
                                    </div>
                                
                                    <div className="row col-12 w-100 marginbottom">
                                        {distanciahtml}  

                                        <div className="textmodal col-6">
                                            <a href={"/profile/"+userID} ><span><FontAwesomeIcon icon={faUser} alt=""/> {user}</span></a>
                                        </div>
                                    </div>
                                
                                   
                                                
                                    
                                </div>
                            </div>

                            <div className="col-12 col-md-6">
                                <div className="row h-100">
                                <div className="col-12">
                                    <div className='parent'>
                                    { numimages > 0 &&
                                    <AwesomeSlider bullets={false} transitionDelay={200} organicArrows={true} mobileTouch={true} className={"sliderstyles"}>
                                        {data}
                                    </AwesomeSlider>
                                    }
                                    
                                    {/*<span className="addFotosplace" onClick={() =>this.props.history.push(urlphoto)}><FontAwesomeIcon size="1x" color="#007bff" icon={faImages} /> <label>{lang.add_photo}</label></span>*/}
                                    
                                    </div>
                                    
                                </div>                
                            </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8"><span className="tith5">{lang.list_beers}</span></div>
                            <div onClick={() =>this.showToastLegend()} className="col-4 text-right">
                                <FontAwesomeIcon color="#08c" icon={faQuestion} style={{cursor:'pointer'}}/>
                                <span className="leyenda">{lang.leyenda}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="place" onClick={() =>this.props.history.push(urladd)}>
                                <div className="row beer">
                                    <div className="col-12 text-center">
                                    <FontAwesomeIcon icon={faPlusCircle}/>  {lang.add_beer}
                                    </div>
                                </div>
                            </div>
                            {place.beers.map((beer) => {
                                            if(this.props.params.beerID != null && this.props.params.beerID == beer.beerID){
                                                beerPreSelect = beer;    
                                            }
                                            return <BeerModal
                                            key={beer.beerID}
                                            beer={beer}
                                            lang={lang}
                                            beerSelected={beerSelected}
                                            handlerClickBeer={() => this.selectBeer(beer)}
                                            history={this.props.history}>
                                            </BeerModal>                                            
                                    })}
                        </div>                        
                    </div>                    
                </Modal>
            );

                }else{
                    return null;
                }
     }
}
 
export default withRouter(ModalPlace);