import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import '../css/main.css';
import GoogleMapReact from 'google-map-react'
import Marker from './main/marker'
import Place from './main/place'
import Legend from './main/legend'
import SearchBox from './searchbox'
import mypos from '../images/mypos.png';
import CurrentLocation from './main/currentlocation';
import ModalPlace from './main/modalplace';
import {Helmet} from 'react-helmet';
import { getLang,GetParamsUserAgent,GetUid } from '../functions/ifelse.js';
import ButterToast, { Cinnamon,POS_TOP, POS_CENTER  } from 'butter-toast';
import { GetUserPosition, urlWS, apikeywbm, baseurl } from '../functions/ifelse.js';
import g_icon from '../images/g_icon.png'
import e_map from '../images/e_map.png'
import { set } from "idb-keyval";
import { get } from "idb-keyval";
//para importar la función add desde otro archivo: import { add } from './math.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle, faQuestion, faSearch} from '@fortawesome/free-solid-svg-icons'

var showLegend = false;
var longclickpressed = false;
var lang, params = null;
var primerrender = true;
var placePreSelect = null;
var urlGoToCreate;
let center={
    lat:40.36,
    lng:-5.92
};  

const mapOptions = maps => {
    return {
        gestureHandling: "greedy",
      fullscreenControl: false,
      mapTypeControl: true,
      streetViewControl: true,
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_BOTTOM,
        style: maps.ZoomControlStyle.SMALL
      },
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap','satellite'],
        position: maps.ControlPosition.BOTTOM_CENTER,
        style: maps.ZoomControlStyle.SMALL
      }  ,
      //esto muestra los marcadores de google (restaurantes ....)
      styles: [
        {
            featureType: 'poi',
            stylers: [{ visibility: 'on' }],
          }
        ]
    };
  };
  let scrollplaces = "h-100-scroll";
  let positionUser, userlat,userlng = null;
  let arr_gplaceID = [];
  

class Main extends Component {
    

    constructor(props){
        super(props);
        //si tenemos guardada la pos en las cookies
        [userlat,userlng] = GetUserPosition(props);
        if(userlat!=null && userlng !=null){
            center={
                lat:userlat,
                lng:userlng
            };  
        }
        
        this.state ={
            error:null,
            isLoaded: false,
            places:[],
            allPlaces:[],
            selectedPlace: null,
            selectedBeer: null,
            search: '',
            mapacargado : false,
            mapa:null,
            mapas:null,
            lang:null,
            currentPosition:{lat:null,lng:null},
            modalIsOpen:false,
            flag:0
        }
        
        this.selectBeer = this.selectBeer.bind(this);
        this.UpdateCenterUser = this.UpdateCenterUser.bind(this);
        this.handleButtonPress = this.handleButtonPress.bind(this)
        this.handleButtonRelease = this.handleButtonRelease.bind(this)

        lang = getLang();

        params = this.props.match.params;
        let url  = window.location.href;
        if(url.search('f=1') !== -1)
           showLegend = true;

        
        if(window.cordova){
            scrollplaces = "h-100";
        }   
    }

     handleButtonPress () {
        console.log("buttonPress")
        this.buttonPressTimer = setTimeout(() => {console.log('long press activated');longclickpressed = true}, 1500);
      }
      
      handleButtonRelease () {
        console.log("buttonRelease")
        clearTimeout(this.buttonPressTimer);
      }

    componentDidUpdate(){
        if(primerrender && placePreSelect != null){
            console.log ("seleccccionado click en "+placePreSelect);
            //esto da problemas
            this.PreselectPlace(placePreSelect);
            primerrender = false;
        }
    }
    
    SAFE_componentWillMount() {
        this.clickMarker = this.clickMarker.bind(this);
   }

    async componentDidMount(){
        
        console.log("didmountttt");

        var placesIndexedBD = await get('json_places');
        

        navigator.geolocation.getCurrentPosition(
            position => {
                /*var cPosition={
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }  
                this.setState((state) => {
                    // Importante: lee `state` en vez de `this.state` al actualizar.
                    return {currentPosition:cPosition}
                });*/
                    let center2={
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }   
                    positionUser = center2;
                    center = center2;
                    console.log ("centrado" + position);
                    localStorage.setItem('lat', position.coords.latitude);
                    localStorage.setItem('lng', position.coords.longitude);
                    if(this.state.mapacargado && center.lat != position.coords.latitude && center.lng != position.coords.longitude)
                        this.UpdateCenter(center2);
            },
            error => console.log(error.message),
            { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
        );

        console.log("didmoun2");
        let data = GetParamsUserAgent();
        let uid = GetUid();
        let uidnuevo = null;
            
        if(placesIndexedBD != null){
            console.log("tenemos guardado en indexedbd");
            //Si yo tengo un json, te mando el vbd, y ya depende de si está actualizado o no tú me mandas el json
            await fetch(urlWS+'place/listwithbeers.php'+data)
            .then(res => res.json())
            .then(
                (result) => {
                uidnuevo = result.uid;
                if(result && result.records.length > 0){
                    //si me han devuelto datos, me quedo con esos y guardo el nuevo vbd
                    localStorage.setItem('vbd', result.vbd)
                    //guardamos el json completo en indexedBD
                    set('json_places', result.records);

                    

                    this.setState((state) => {
                        return {isLoaded: true,places: result.records,allPlaces: result.records, flag:this.state.flag+1}
                    });
                }
                else{
                    //si no me has devuelto un json, me quedo con el que tengo guardado: placesIndexedBD
                    this.setState((state) => {
                        return {isLoaded: true,places: placesIndexedBD,allPlaces: placesIndexedBD, flag:this.state.flag+1}
                    });
                }

                },
                (error) => {
                this.setState((state) => {
                    return {isLoaded: true,error:error, flag:this.state.flag+1}
                });
                }
            )
        }
        else{
            console.log("tomamos json por primera vez");
            //si no tengo ni te lo mando porque me tienes que mandar un json
            await fetch(urlWS+'place/listwithbeers.php'+data)
            .then(res => res.json())
            .then(
                (result) => {
                   
                uidnuevo = result.uid;
                if(result && result.records.length > 0){
                    //si me han devuelto datos, me quedo con esos y guardo el nuevo vbd
                    localStorage.setItem('vbd', result.vbd)
                    
                    //guardamos el json completo en indexedBD
                    set('json_places', result.records);
                    this.setState((state) => {
                        return {isLoaded: true,places: result.records,allPlaces: result.records, flag:this.state.flag+1}
                    });
                }
                else{
                    //Ha habido un error
                    this.setState((state) => {
                        return {isLoaded: true,error:"error", flag:this.state.flag+1}
                    });
                }

                },
                (error) => {
                    console.log("error");
                    console.log(error);
                this.setState((state) => {
                    return {isLoaded: true,error:error, flag:this.state.flag+1}
                });
                }
            )

        }

        //Si no tenía uid y lo hemos obtenido por primera vez lo guardamos
        if((uid == null || uid == "null" || !uid) && uidnuevo != null){
            localStorage.setItem('uid', uidnuevo)
        }
        

    }

    

    

    toggleShowModal(){
        this.setState({modalIsOpen: !this.state.modalIsOpen, flag:this.state.flag+1})
    }

    selectBeer=(beer, place)=>{
        this.OcultarButterToastAndDiv();
        let map = this.state.mapa;
        let loc = {
            lat: parseFloat(place.lat),
            lng: parseFloat(place.lng)
        }
        map.setCenter(loc);
        //map.setZoom(20);
        this.setState((state) => {
            // Importante: lee `state` en vez de `this.state` al actualizar.
            return {modalIsOpen:true,selectedBeer:beer, selectedPlace:place, flag:this.state.flag+1}
        });
    };

    showToastAddLocal() {
        var texto = lang.how_add_local;
        if(window.cordova)
            texto = lang.how_add_local_cordova;
        
        this.OcultarButterToastAndDiv();
        ButterToast.raise({
            timeout: 6000,
            content: <Cinnamon.Crisp scheme={Cinnamon.Crisp.SCHEME_BLUE}
            content={<div className="container toastcontent">
                        {texto}
                        <div className="row">
                            <div className="col-6"><img src={g_icon} /></div>
                            <div className="col-6"><img src={e_map} />
                        </div>
                        </div>
                    </div>}
            title={<div className="container toasttitle">{lang.add_local}</div>}/>
        });
    }

    

    showToastLegend() {
        this.OcultarButterToastAndDiv();
        console.log("se va a lanzar");
        ButterToast.raise({
            timeout: 8000,
            content: <Cinnamon.Crisp scheme={Cinnamon.Crisp.SCHEME_BLUE}
            content={<Legend lang={lang}/>}
            title={<div className="container toasttitle">{lang.leyenda}</div>}/>
            });
        }
    

        //cuando clicamos en un place en el lado derecho
    PreselectPlace=(place)=>{
        this.setState((state) => {
            // Importante: lee `state` en vez de `this.state` al actualizar.
            return {modalIsOpen:true,selectedPlace:place, flag:this.state.flag+1}
        });
        
    };
    
        //cuando clicamos en un place en el lado derecho
    selectPlace=(place)=>{
        this.OcultarButterToastAndDiv();
        let map = this.state.mapa;
        let loc = {
            lat: parseFloat(place.lat),
            lng: parseFloat(place.lng)
        }
        map.setCenter(loc);
        //map.setZoom(20);
        this.setState((state) => {
            // Importante: lee `state` en vez de `this.state` al actualizar.
            return {modalIsOpen:true,selectedPlace:place, flag:this.state.flag+1}
        });
        
    };

    clickBuscado(event){
        let map = this.state.mapa;
        map.setCenter(event);
        map.setZoom(12);
    }

    OcultarButterToastAndDiv(){
        document.getElementById("gotocreate").style.display = "none"; 
        ButterToast.dismissAll();
    }

    //cuando clicamos en un marcador
    clickMarker(placePasado) {
        //cerramos el infowindow que saltaría en maps
        this.OcultarButterToastAndDiv();
        this.setState((state) => {
            return {modalIsOpen:true,selectedPlace:placePasado, selectedBeer:null, flag:this.state.flag+1}
        });
    }

    //esta se llama cada vez qeu se escribe en la búsqueda
    handleChangeSearch=(event)=>{
        this.setState({
            search: event.target.value,
            places: this.state.allPlaces.filter((place)=> new RegExp(this.state.search,'i').exec(place.name))
        });
    };


    //esta se llama cada vez qeu se mueve zoom o lat o lng
    onMapChange = ({ center, zoom, bounds }) => {
        if(this.state.mapacargado)
            this.UpdateBounds(bounds.sw, bounds.ne);
    }

    showGoToCreate() {
        //document.getElementById("gotocreate").style.display = "block";    
        this.props.history.replace(urlGoToCreate);
    }

    hideGoToCreate(){
        document.getElementById("gotocreate").style.display = "none"; 
    }



    //esta función sólo se llama una vez al cargar el mapa
    onLoadApi = ({map, maps}) => {
        //watch position hace seguimiento tipo gps y debería ir moviéndose, habrá que probar con el móvil, si no se mueve habrá que llamar a una función tipo navigator.geolocation.watchPosition(nombrefuncion);
        //navigator.geolocation.watchPosition(
        
        
        console.warn("onloadapi");
        
        let b = map.getBounds();
        let ne = b.getNorthEast();
        let sw = b.getSouthWest();
        ne.lat = ne.lat();
        ne.lng = ne.lng();
        sw.lat = sw.lat();
        sw.lng = sw.lng();
        //this.UpdateBounds(sw,ne); Lo hacemos todo junto para evitar renders
        this.setState((state) => {
            // Importante: lee `state` en vez de `this.state` al actualizar.
            return {mapa:map, mapas:maps,mapacargado:true,places: this.state.allPlaces.filter((place)=>  place.lat >= sw.lat && place.lat <= ne.lat && place.lng >= sw.lng && place.lng <= ne.lng && new RegExp(this.state.search,'i').exec(place.name))}
        });    

        
        
        

        function OcultarButterToastAndDivScopeMAP(){
            document.getElementById("gotocreate").style.display = "none"; 
            ButterToast.dismissAll();
        }

        //CLICK DERECHO SE PERMITE EN pc
        map.addListener('rightclick', function(event){
            event.stop();
            OcultarButterToastAndDivScopeMAP();
            GestionaEvent(event);
        });
        
        map.addListener('click', function(event){
            event.stop();
            OcultarButterToastAndDivScopeMAP();

            if (event.placeId) {                
                //SI ES UN CLICK EN UN GOOGLE PLACE SE PERMITE EN AMBOS ENTORNOS (MOBIlE Y WEB)
                GestionaEvent(event); 
            }

            //SI ES UN CLICK LARGO SE PERMITE EN MÓVIL
            if(longclickpressed == true && window.cordova){  
                GestionaEvent(event); 
            }
            console.log("anulamos el click");
            longclickpressed = false;            
        });

        function GestionaEvent(event){            
            var lng = event.latLng.lng();
            var lat = event.latLng.lat();    
            //este if comprueba que no esté en nuestra BD                 
            if(arr_gplaceID.indexOf(event.placeId) < 0){
                ShowGoToCreate(lat,lng, event.placeId);  
            }
               
        }

        function ShowGoToCreate(lat,lng, id){
            var local_ext = lang.add_local_ext;
            var local_g = lang.add_local_g;
            if(id){
                //place from google
                document.getElementById("linkcreate").innerText = local_g;
                urlGoToCreate = "/create/"+lat+"/"+lng+"/"+id;
            }
            else{
                //place in any point of the map
                document.getElementById("linkcreate").innerText = local_ext  + ": ("+lat.toFixed(4)+","+lng.toFixed(4)+")";  
                urlGoToCreate = "/create/"+lat+"/"+lng;          
            }
            
            document.getElementById("gotocreate").style.display = "block"; 
        }

       

        console.log("se va a mostrar la leyenda "+showLegend);
        if(showLegend){
            this.showToastLegend();
            showLegend = false;
        }

    }



    UpdateBounds (sw,ne){
        console.log("updateBounds");
        this.setState((state) => {
            // elegimos sólo los que estén en ese cuadro de lat,lng
            return {places: this.state.allPlaces.filter((place)=>  place.lat >= sw.lat && place.lat <= ne.lat && place.lng >= sw.lng && place.lng <= ne.lng && new RegExp(this.state.search,'i').exec(place.name)), flag:this.state.flag+1}
        });        
    }

    UpdateCenter (position){
        let map = this.state.mapa;
        map.setCenter(position);
        map.setZoom(16);
    }

    UpdateCenterUser (){
        let map = this.state.mapa;
        alert(positionUser);
        map.setCenter(positionUser);
        map.setZoom(12);
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log(this.state);
        console.log(nextState)
        return this.state.flag != nextState.flag;
    }

    
    
  
    render() {
        /* activar esto si queremos centrar el mapa en el que seleccionemos
        if (this.state.selectedPlace){
            center={
                lat: parseFloat(this.state.selectedPlace.lat),
                lng: parseFloat(this.state.selectedPlace.lng)
            }
        }
        */
        
        if(userlat != null && userlng != null){
            center={
                lat: parseFloat(userlat),
                lng: parseFloat(userlng)
            }
        }


        console.log(center);

        const {error, isLoaded } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div className="loading_center">
            <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-secondary" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-success" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-danger" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-warning" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-info" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-light" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-dark" role="status">
            <span className="sr-only">Loading...</span>
            </div>
        </div>
        } else {
        
            console.log("is Loaded" + isLoaded)
            console.log("render main");

            let divmap;
            if(window.cordova){
                divmap = " h-70 nopadding";
                }
            else{
                divmap = " h-100 nopadding";
            }

            var desc = "";
            var meta = null;
            if(params.placeID != null){
                this.state.places.map((place) => {
                    if(params.placeID == place.placeID){

                        desc = place.name + " | ";
                        place.beers.map((beer) => {
                            desc += beer.name+ ",";
                        })
                        desc = desc.slice(0, -1);
                        console.log(desc)
                        meta = <Helmet>
                            <title>{"WorldBeerMap | "+place.name}</title>
                            <meta name="title" content={"WorldBeerMap | "+place.name} />
                            <meta name="description" content={desc} />
                            <meta name="keywords" content={lang.meta_keys} />

                            <meta name="twitter:card" content="summary" />
                            <meta name="twitter:site" content="@worldbeermap"/>
                            <meta name="twitter:title" content={"WorldBeerMap | "+place.name}/>
                            <meta name="twitter:description" content={desc} />

                            <meta property="og:title" content={"WorldBeerMap | "+place.name} />
                            <meta property="og:site_name" content="WorlBeerMap" />
                            <meta property="og:description" content={desc} />
                            <meta property="og:image" content={baseurl+place.images[0].image} />

                            <link rel="canonical" href={"https://worldbeermap.com/place/"+place.placeID+"/"+place.name} />
                            <link href={"https://worldbeermap.com/place/"+place.placeID+"/"+place.name} rel="alternate"  hreflang="en"/>
                            <link href={"https://en.worldbeermap.com/place/"+place.placeID+"/"+place.name} rel="alternate"  hreflang="en"/>
                            <link href={"https://es.worldbeermap.com/place/"+place.placeID+"/"+place.name} rel="alternate"  hreflang="es"/>
                        </Helmet>     
                    }
                })
            }
            
                

            return (        
                <div className="container-fluid h-100-50" lang={lang.lang}>
                    {meta}
                    
                    
                    <div className="col-12">
                    <ModalPlace params={params} lang={lang} abierto={this.state.modalIsOpen} place={this.state.selectedPlace} beer={this.state.selectedBeer} loc={this.state.currentPosition} toggleShowModalCallback={this.toggleShowModal.bind(this)}>
                    </ModalPlace>
                    </div>
                        
                    
                    <div className="row h-100">
                        <div className={"col-lg-9 col-12" + divmap}>
                        
                        
                        <div className="mypos">
                            <img src={mypos} onClick={this.UpdateCenterUser} width={20}/>
                        </div>

                        <div id="gotocreate" className="map-create-place alert alert-light">
                            <h6 className="alert-heading">{lang.add_local}</h6>
                            <p className="mb-0"><span onClick={() => this.showGoToCreate()} id="linkcreate"></span></p>
                            <button onClick={() => this.hideGoToCreate()} className="btn-dismiss">×</button>
                        </div>
                        
                        <div className="map h-100" onTouchStart={this.handleButtonPress} 
                                onTouchEnd={this.handleButtonRelease} 
                                onMouseDown={this.handleButtonPress} 
                                onMouseUp={this.handleButtonRelease} 
                                onMouseLeave={this.handleButtonRelease}>
                            {this.state.mapacargado === true && 
                            <SearchBox map={this.state.mapa} mapsapi={this.state.mapas} lang={lang} handlerPlaceChanged={(event) => this.clickBuscado(event)}/>
                            }
                            
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: apikeywbm,libraries: ['places'], language: 'en',
                                region: 'en' }}
                                center={center}
                                zoom={6}
                                onChange={ this.onMapChange }
                                onGoogleApiLoaded={ this.onLoadApi.bind(this) }
                                options={mapOptions}
                                yesIWantToUseGoogleMapApiInternals
                                >                            

                                <CurrentLocation
                                    key="currentlocation"
                                    lat={this.state.currentPosition.lat}
                                    lng={this.state.currentPosition.lng}
                                />
                                {this.state.places.map((place) => {
                                    if(place.beers.length > 0){
                                    return <Marker
                                        key={place.placeID}
                                        lat={place.lat}
                                        lng={place.lng}
                                        text={place.placeID}
                                        type={place.type}
                                        selected={place === this.state.selectedPlace}
                                        handlerMarker={() => this.clickMarker(place)}
                                    ></Marker>
                                    }
                                })}
                            </GoogleMapReact>
                        </div>
                        </div>
                    
                        <div className={scrollplaces + " col-lg-3 col-12 nopadding"}>
                            <ButterToast timeout={100000} position={{vertical: POS_TOP,  horizontal: POS_CENTER }} style={{ top: '100px',  right: '100px' }}/>
                            <div className="container">


                            <div className="input-group md-form form-sm form-1 my-4 pl-0">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"> 
                                    <FontAwesomeIcon icon={faSearch} aria-hidden="true"/>
                                    </span>
                                </div>
                                <input
                                    className='form-control my-0 py-0'
                                    type='text'
                                    aria-label='Search'
                                    placeholder={lang.search_place}
                                    value={this.state.search}
                                    onChange={this.handleChangeSearch}
                                />
                            </div>

                            <div className="row">
                                <div className="col-8"><span className="tith5">{lang.list_locales_cervezas}</span></div>
                                <div onClick={() =>this.showToastLegend()} className="col-4 text-right">
                                    <FontAwesomeIcon color="#08c" icon={faQuestion} style={{cursor:'pointer'}}/>
                                    <span className="leyenda">{lang.leyenda}</span>
                                </div>
                            </div>
                            
                                
                                <div className="place" onClick={() =>this.showToastAddLocal()}>
                                    <div className="row beer" style={{padding:'0px'}}>
                                        <div className="col-12 text-center">
                                        <FontAwesomeIcon icon={faPlusCircle}/>  {lang.add_local}
                                        </div>
                                    </div>
                                </div>
                            
                                {this.state.places.map((place) => {
                                    if(place.gPlaceID != null)
                                        arr_gplaceID.push(place.gPlaceID);

                                    if(params.placeID != null && params.placeID == place.placeID){
                                        placePreSelect = place;    
                                        
                                    }

                                    return (
                                    <Place
                                        loc={this.state.currentPosition}
                                        key={place.placeID}
                                        place={place}
                                        handleClick={this.selectPlace}
                                        selected={place === this.state.selectedPlace}
                                        selectBeer={(beer) => this.selectBeer(beer,place)}
                                        lang={lang}>    
                                    </Place>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                );
            }
        }
}

export default Main;

/*
//AIzaSyAT7gRXBGjF9DdWUkvDdjsQcedMgwg4r3k


*/ 