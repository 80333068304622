import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { render } from "react-dom";
import './css/index.css';

import App from "./components/app";

const startApp = () => {
    render(
        <Router>
            <App />
        </Router>,
        document.getElementById("root")
    );
}

if(window.cordova){
    document.addEventListener('deviceready', startApp, false);   
}
else{
    startApp();
}