import React from "react";
import { faUtensils, faCocktail } from '@fortawesome/free-solid-svg-icons'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import {faCompactDisc} from '@fortawesome/free-solid-svg-icons'
import {faGlassMartiniAlt} from '@fortawesome/free-solid-svg-icons'
import {faBeer} from '@fortawesome/free-solid-svg-icons'
import {faWineGlassAlt} from '@fortawesome/free-solid-svg-icons'
import { set } from "idb-keyval";
import { get } from 'idb-keyval';
import { del } from 'idb-keyval';
var idiomas = require("../functions/idiomas.json");

export const apikeywbm =  'AIzaSyD792Mt_W7YPcwJiSiRCV3CmfaZZaOljvc';
export const apikeyekibike =  'AIzaSyAT7gRXBGjF9DdWUkvDdjsQcedMgwg4r3k';
/*
export const baseurl = "http://localhost/worldbeermap/ws/photos/";
export const urlWS = "http://localhost/worldbeermap/ws/";
export const baseweb = "http://localhost:3000/";
*/
export const baseurl = "https://worldbeermap.com/ws/photos/";
export const urlWS = "https://worldbeermap.com/ws/";
export const baseweb = "https://worldbeermap.com/";

export const versionCode = "1.0.0";
//Versión de la base de datos (lo devuelve el ws y cambia con cada cambio)
export const VBD = 0;




export function GetParamsUserAgent(){
    return '?osName='+localStorage.getItem('osName')
    +'&osVersion='+localStorage.getItem('osVersion')
    +'&browserName='+localStorage.getItem('browserName')
    +'&browserVersion='+localStorage.getItem('browserVersion')
    +'&deviceType='+localStorage.getItem('deviceType')
    +'&userID='+localStorage.getItem('userID')
    +'&uid='+localStorage.getItem('uid')
    +'&vbd='+localStorage.getItem('vbd')
    +'&lat='+localStorage.getItem('lat')
    +'&lng='+localStorage.getItem('lng');
}

export function GetUid(){
    return localStorage.getItem('uid');
}

export function CheckLogin(props){
        const user =  localStorage.getItem('userID') ;
        if (user == null) {
            alert("Login required");
            props.history.push('/login?href='+window.location.pathname);
        }
        else{
            return localStorage.getItem('userID');
        }
}

export async function SetJson(value){
    //await set('json_places', value);
    alert("setjson");
    await set('json_places', value);
}

export async function fetchJson() {
    const response = await fetch(urlWS+'place/listwithbeers.php');
  
    if (!response.ok) {    const message = `An error has occured: ${response.status}`;    throw new Error(message);  }
    const res = await response.json();
    return res;
}

export async function GetJson(){
    var places = await get('json_places');
    return places;
}

export function GetUserPosition(){
    const user =  localStorage.getItem('userID') ;
    if (user == null) {
        return [null,null];
    }
    else{
        return [localStorage.getItem('lat'), localStorage.getItem('lng')];
    }
}

export function GetUserLogged(){
    const user =  localStorage.getItem('userID') ;
    if (user == null) {
        return null;
    }
    else{
        return {
            userID:  localStorage.getItem('userID'),
            username: localStorage.getItem('user')
          };
    }
}

export function Icontypebar(type){
    var typebar = faUtensils;
    if(type === "coffee")
        typebar = faCoffee;
    else if(type === "bar")
        typebar = faBeer;
    else if(type === "night_club")
        typebar = faCocktail;

    return typebar;
}

export function capitalize (s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  export function getLang(){
    //Damos preferencia a que el subdominio del idioma marque el idioma es. o en.
    var subdomain = window.location.host.split('.')[0];


    if(subdomain && subdomain == 'es'){
        localStorage.setItem('lang','es');
        return idiomas.es; 
    }
    else if(subdomain && subdomain == 'en'){
        localStorage.setItem('lang','en');
        return idiomas.en; 
    }

    //si no manda nada de la url, lo que el usuario tenga guardado
    var lang_storage = localStorage.getItem('lang') ;
    if(lang_storage && lang_storage == 'es'){
        return idiomas.es; 
    }
    else if(lang_storage && lang_storage == 'en'){
        return idiomas.en; 
    }

    //Si no viene en la url, vamos a mostrarlo en inglés
    return idiomas.en;
    
  }

  export function getLangCode(){
    //Damos preferencia a que la variable del idioma venga en la url
    var subdomain = window.location.host.split('.')[0];

    if(subdomain && subdomain == 'es'){
        return 'es'; 
    }
    else if(subdomain && subdomain == 'en'){
        return 'en'; 
    }

    //si no manda nada de la url, lo que el usuario tenga guardado
    var lang_storage = localStorage.getItem('lang') ;
    if(lang_storage && (lang_storage == 'es' || lang_storage == 'es')){
        return lang_storage;
    }

    return 'en';    
  }

  export function GetTipo(types){
    var posibilidades = ["bar","restaurant","night_club","cafe"];

    for(var i =0;i < posibilidades.length ; i++){
        if(types.indexOf(posibilidades[i]) > -1)
            return posibilidades[i];
    }
}




export function distance(loc1, loc2) {
	if (loc1 == null || loc2 == null || loc1.lat == null || loc2.lat == null || (loc1.lat1 == loc2.lat2) && (loc1.lng == loc2.lng)) {
		return null;
	}
	else {
		var radlat1 = Math.PI * loc1.lat/180;
		var radlat2 = Math.PI * loc2.lat/180;
		var theta = loc1.lng-loc2.lng;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
        
        dist = dist * 1.609344;
        if(dist < 1){
            dist = dist.toFixed(1);
        }
        if(dist > 1){
            dist = dist.toFixed(0);
        }
		return dist+ " Km";
	}
}