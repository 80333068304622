import {withRouter} from 'react-router-dom'
import React from "react";
import Legend from './main/legend'
import { GetUserLogged } from '../functions/ifelse.js';
import { Component } from "react";
import '../css/create.css';
import '../css/index.css';
import PlaceProfile from './main/placeprofile';
import BeerProfile from './main/beerprofile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faQuestion, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import { urlWS, getLang, GetParamsUserAgent } from '../functions/ifelse.js';
import {bottomcode} from '../functions/ifelse.js';
import ButterToast, { Cinnamon,POS_TOP, POS_CENTER  } from 'butter-toast';


let params,mensaje = null;
var classCompleted = "alert alert-danger";


var lang, user, userProfileID;


class Profile extends Component {
    
    constructor(props){
        super(props);

        user = GetUserLogged(props);

        
        
        params  = this.props.match.params;

        userProfileID = params.id;
        var isPropietario = false;
        if(user != null && user.userID == userProfileID){
            isPropietario = true;
        }
        
        lang = getLang();       


        this.state ={
            username:null,
            isPropietario: isPropietario,
            places:[],
            beers:[],
            email:null,
            datebirth:null,
            loading:true,
            completed:false
        }
        
        
    }

    componentWillMount(){
        var self = this;
        let data = GetParamsUserAgent();
        fetch(urlWS+'user/read.php'+data+'&id='+userProfileID)
                .then(res => res.json())
                .then(
                    (user) => {
                        console.log(user);
                        
                        self.setState({ 
                            username:user.username,
                            places:user.places,
                            beers:user.beers,
                            email:user.email,
                            datebirth:user.datebirth,
                            loading:true,
                            completed:false
                        });

                        self.setState({ 
                            name:user.username,
                            loading: false});  
                    },
                    (error) => {
                    console.log(error);
                    }
                )
    }

    Ver(beer){
        this.props.history.push("/place/"+beer.placeID+"/"+beer.beerID)
    }

    Editar(beer){
        this.props.history.push("/editbeer/"+beer.beerID)
    }

    VerPlace(place){
        this.props.history.push("/place/"+place.placeID)
    }

    EditarPlace(place){
        this.props.history.push("/edit/"+place.placeID)
    }

    Home(){
        this.props.history.push("/")
    }
  
    refreshPage() {
        window.location.reload(false);
    }

    showToastLegend() {
        ButterToast.dismissAll();
        ButterToast.raise({
            timeout: 6000,
            content: <Cinnamon.Crisp scheme={Cinnamon.Crisp.SCHEME_BLUE}
            content={<Legend lang={lang}/>}
            title={<div className="container toasttitle">{lang.leyenda}</div>}/>
            });
    }


    render() {
        if(this.state.loading == true){
            return(
            <div className="loading_center">
                <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-secondary" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-success" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-danger" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-warning" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-info" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-light" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-dark" role="status">
                <span className="sr-only">Loading...</span>
                </div>
            </div>
            );
        }
        else if(this.state.completed == true){
            
            return(
                <div className="loading_center">
                    <div className={classCompleted}>
                        <div>{mensaje}</div>   
                    </div>
                </div>
                );
        }
        else{
        
        
        return (

            <div className="container h-100">
                <ButterToast timeout={100000} position={{vertical: POS_TOP,  horizontal: POS_CENTER }} style={{ top: '100px',  right: '100px' }}/>    
                <div className="container col-md-6 col-xs-12">
                    <div className="margin-top-20 row"><h4><b>{this.state.username}</b></h4></div>
                    <div className="row margin-top-20">   
                        <div className="row w-100">
                            <div className="col-8"><span className="tith5">{lang.list_beers_user+this.state.username}</span></div>
                            <div  className="col-4 text-right"  onClick={() =>this.showToastLegend()}>
                                <FontAwesomeIcon color="#08c" icon={faQuestion} style={{cursor:'pointer'}}/>
                                <span className="leyenda">{lang.leyenda}</span>
                            </div>
                        </div>
                        
                        
                                    
                        {this.state.beers.map((beer) => {
                                        return (
                                        <div key={"div_"+beer.beerID} className="row w-100">
                                        <div className="col-10">
                                        <BeerProfile
                                            key={beer.beerID}
                                            beer={beer}    
                                            lang={lang}
                                            handlerClickBeer={() => this.Ver(beer)}>   
                                        </BeerProfile>
                                        
                                        </div>
                                        {/*<div className="col-2 accion">
                                            {this.state.isPropietario &&
                                            <div className="row inline">
                                                <div className="col-6"><FontAwesomeIcon icon={faEdit} onClick={() => this.Editar(beer)}/> </div>
                                                <div className="col-6"><FontAwesomeIcon icon={faTrashAlt} onClick={() => this.Borrar(beer)}/> </div>
                                            </div>
                                            }
                                            {!this.state.isPropietario &&
                                            <FontAwesomeIcon icon={faEye} onClick={() => this.Ver(beer)}/> 
                                            }
                                        </div>*/}
                                        <div className="col-2 accion">
                                            {this.state.isPropietario &&
                                            <FontAwesomeIcon icon={faEdit} onClick={() => this.Editar(beer)}/> 
                                            }
                                            {!this.state.isPropietario &&
                                            <FontAwesomeIcon icon={faEye} onClick={() => this.Ver(beer)}/> 
                                            }
                                        </div>
                                        </div>
                                        )
                                    })
                        }
                        <div className="w-100" style={{cursor:'pointer'}} onClick={() => this.Home()}>
                            <div className="row ">
                                <div className="col-12 text-center">
                                <FontAwesomeIcon icon={faPlusCircle}/>  {lang.add_beer}
                                </div>
                            </div>
                        </div>

                        
                        <div className="row w-100 margin-top-20">
                            <div className="col-12 margin-top-20"><span className="tith5">{lang.places_user+this.state.username}</span></div>
                        </div>
                        
                        
                        {this.state.places.map((place) => {
                                        return (
                                        <div key={"divp_"+place.placeID} className="row w-100">
                                            <div className="col-11">
                                                <PlaceProfile
                                                    key={place.placeID}
                                                    place={place}
                                                    handlerClickPlace={() => this.VerPlace(place)}>   
                                                </PlaceProfile>
                                            </div>
                                            <div className="col-1 accion">
                                                {this.state.isPropietario &&
                                                <FontAwesomeIcon icon={faEdit} onClick={() => this.EditarPlace(place)}/> 
                                                }
                                                {!this.state.isPropietario &&
                                                <FontAwesomeIcon icon={faEye} onClick={() => this.VerPlace(place)}/> 
                                                }
                                            </div>
                                        </div>
                                        )
                                    })
                        }
                        <div className="w-100" style={{cursor:'pointer',marginTop:'5px'}} onClick={() => this.Home()}>
                            <div className="row ">
                                <div className="col-12 text-center">
                                <FontAwesomeIcon icon={faPlusCircle}/>  {lang.add_local}
                                </div>
                            </div>
                        </div>

                        
                    </div>
                    
                </div>
                
            </div>
                
                
        );
        }
       
    }
};

export default withRouter(Profile);
