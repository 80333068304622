import {withRouter} from 'react-router-dom'
import React from 'react'
import '../../css/beers.css'
import Comment from './comment'
import { GetUserLogged } from '../../functions/ifelse.js';
import { capitalize } from '../../functions/ifelse.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBeer, faEuroSign, faGlassWhiskey, faPercentage, faUser, faTag, faFlag, faBuilding, faList, faCheck, faTimes, faSortUp, faImages, faEdit } from '@fortawesome/free-solid-svg-icons'
import { faWineBottle } from '@fortawesome/free-solid-svg-icons'
import { faHandPaper } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { baseurl } from '../../functions/ifelse.js';
import { urlWS, GetParamsUserAgent } from '../../functions/ifelse.js';
import AwesomeSlider from 'react-awesome-slider';
let lang = null;
let user, beerID, clase;

class BeerModal extends React.Component{

    constructor(props){
        super(props);
        lang=this.props.lang;
        this.state = {
            showComments : false,
            rating: -1,
            comment:'',
            enviado: false
        }
        user = GetUserLogged(props);
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({comment: val});  
        
    }

    myClickStar(n) {
        var self= this;
        return function () {
            self.setState({rating:n})
         }
    }

    ShowComments=()=>{
        if(this.state.showComments == true)
            this.setState({showComments: false});
        else  
            this.setState({showComments: true});
    }

    convertToParamas(objeto){ //operations
        return {
            'rating':objeto.rating,
            'comment':objeto.comment,
            'userID':user.userID
        }
     }

    mySubmitHandler = (event) => {
        event.preventDefault();

        if(this.state.rating < 1){
            alert("No rating");
            return;
        }

        //aquí debemos de llamar al ws para crear el local y pasar this.state
        var params = this.convertToParamas(this.state);
        const self = this;
          
        
        (async () => {
            let data = GetParamsUserAgent();
            const rawResponse = await fetch(urlWS+'beer/addcomment.php'+data+'&id='+beerID, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Content-Type':'application/x-www-form-urlencoded' //sin esta línea da error
              },
              body: JSON.stringify(params)
            });
            const response = await rawResponse.json();          
            var id = response.id;
            if(id > 0){
                clase = "success";
            }
            else{
                clase = "danger";
            }
            self.setState({ 
                enviado:true
            });
        })();
    }
    
    handleclick=()=>{
        this.props.handleClick(this.props.beer)
    };
    render(){
        
        let isSelected = false;
        const beer = this.props.beer;
        var leng = lang.lang;
        const title= beer.name;
        const color= beer.color;
        const recipient= beer.recipient;
        const volume= beer.volume / 100;
        const alcohol = beer.alcoholPercent;
        const numratings = beer.numRatings;
        const style= beer.style;
        const brand= beer.brand;
        const country= beer.country;
        const gluten= beer.withoutGluten;
        const ingredients= beer.ingredients;
        const priceHappyHour= beer.priceHappyHour;
        let rating= parseFloat(beer.rating);
        rating = rating.toFixed(1);
        let price= parseFloat(beer.price);
       
        if(price >= 10)
            price = price.toFixed(0);
        else 
            price = price.toFixed(1);

        var pricexvol = price / volume;

        if(pricexvol >= 10)
            pricexvol = pricexvol.toFixed(0);
        else 
            pricexvol = pricexvol.toFixed(1);

        let imgB = faBeer;
        let handmade = faHandPaper;

        const made = beer.made;
        if(made == "comercial")
            handmade = faBuilding;

        if(recipient == "bottle"){
            imgB = faWineBottle;
        }

        let glutenicon = faCheck;
        if(gluten == "1"){
            glutenicon = faTimes;
        }
         
        const colorhex = lang.beer_colors_hex[color]
        const classbutton = "btn btn-circle btn-"+color;

        if(this.props.beer === this.props.beerSelected){
            isSelected = true;
            console.log(title + "selected");
            beerID = beer.beerID;
        }
        
        var urledit = "/editbeer/"+beerID;
        var userBeer = beer.username;
        var userIDBeer = beer.userID;

        var beerimages = this.props.beer.images;
        var numimages = this.props.beer.images.length;


        let i =0; 
        let data = [];
        beerimages.map((image) => {
            data.push(<div key={"image"+i} data-src={baseurl+image.image} data-slug={i}/>);
            i++;
        })

        console.log("render modalbeer"+beer.beerID);
        
        return(
            
                <div id={beer.beerID} className="place" onClick ={this.props.handlerClickBeer}>
                     {isSelected === false &&
                    <div className="row beer">
                                <div className="col-lg-8 col-7">
                                    <div className="row">
                                        {title}
                                    </div>
                                        <div className="row stars">
                                                <span><FontAwesomeIcon color={rating > 0 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                <span><FontAwesomeIcon color={rating > 1 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                <span><FontAwesomeIcon color={rating > 2 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                <span><FontAwesomeIcon color={rating > 3 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                <span><FontAwesomeIcon color={rating > 4 ? colorhex : '#bbb'} icon={faStar}/></span>
                                        </div>
                                </div>  



                                <div className="col-lg-4 col-5">
                                    <div className="row">
                                        <button type="button" className={classbutton}>{price+"€"}</button>
                                        <button type="button" className={classbutton}><FontAwesomeIcon icon={imgB}  color= {colorhex}/></button>
                                        <button type="button" className={classbutton}><FontAwesomeIcon icon={handmade}   color= {colorhex}/></button>
                                        <button type="button" className={classbutton}>{volume +"l"}</button>
                                    </div>
                                            
                                </div>
                    </div>
                    }
                    {isSelected === true &&
                    <div className="container beerextra">
                        <div className="row">
                            <div className="col-12 beertitle">
                                            {title} 
                                            {user != null && userIDBeer == user.userID &&
                                            <span onClick={() =>this.props.history.push(urledit)}><FontAwesomeIcon size="1x" icon={faEdit}/></span>
                                            }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <span>{lang.beer_made[made]}</span>
                            </div>
                        </div>    
                        <div className="row">
                            <div className="col-12 starsbeer">
                                    <span onClick={this.ShowComments}><FontAwesomeIcon color={rating > 0 ? colorhex : '#bbb'} icon={faStar}/></span>
                                    <span onClick={this.ShowComments}><FontAwesomeIcon color={rating > 1 ? colorhex : '#bbb'} icon={faStar}/></span>
                                    <span onClick={this.ShowComments}><FontAwesomeIcon color={rating > 2 ? colorhex : '#bbb'} icon={faStar}/></span>
                                    <span onClick={this.ShowComments}><FontAwesomeIcon color={rating > 3 ? colorhex : '#bbb'} icon={faStar}/></span>
                                    <span onClick={this.ShowComments}><FontAwesomeIcon color={rating > 4 ? colorhex : '#bbb'} icon={faStar}/></span>
                                    <span onClick={this.ShowComments}><label>{numratings+ " "+ lang.ratings}</label></span>
                            </div>    
                        </div>
                        <div className="row iconos margintop">
                            <div className="col-2">
                                <button type="button" className={classbutton}><FontAwesomeIcon icon={faEuroSign}  color= {colorhex}/></button><span>{price+"€"}</span>
                            </div>
                            <div className="col-2">
                                <button type="button" className={classbutton}><FontAwesomeIcon icon={faGlassWhiskey}  color= {colorhex}/></button><span>{volume +"l"}</span>
                            </div>
                            <div className="col-2">
                                <button type="button" className={classbutton}><FontAwesomeIcon icon={faEuroSign}  color= {colorhex}/></button><span>{pricexvol+"€/l"}</span>
                            </div>
                            <div className="col-2">
                                <button type="button" className={classbutton}><FontAwesomeIcon icon={faPercentage}  color= {colorhex}/></button><span>{alcohol+"%"}</span>
                            </div>
                            <div className="col-2">
                                <button type="button" className={classbutton}><FontAwesomeIcon icon={imgB}  color= {colorhex}/></button><span>{lang.beer_recipient[recipient]}</span>
                            </div>
                        </div>
                        {priceHappyHour > 0 && <div className="row iconos">
                                    <div className="col-12">
                                    <button type="button" className={classbutton}><FontAwesomeIcon icon={faEuroSign}  color= {colorhex}/></button><span>{priceHappyHour+"€ (Happy Hour)"}</span>
                                    </div>
                        </div>}  
                        <div className="row iconos">
                            
                            
                            <div className="col-2">
                                <button type="button" className={classbutton}><FontAwesomeIcon icon={glutenicon}   color= {colorhex}/></button><span>{lang.gluten}</span>
                            </div>
                            <div className="col-2">
                                {style.length > 0 && <span><button type="button" className={classbutton}><FontAwesomeIcon icon={faTag}  color= {colorhex}/></button><span>{style}</span></span>}
                            </div>
                            <div className="col-4">
                                {brand.length > 0 && <span><button type="button" className={classbutton}><FontAwesomeIcon icon={faBuilding}  color= {colorhex}/></button><span>{brand}</span></span>}
                            </div>
                            <div className="col-3">
                                {country.length > 0 && <span><button type="button" className={classbutton}><FontAwesomeIcon icon={faFlag}  color= {colorhex}/></button><span>{country}</span></span>}
                            </div>
                        </div>                          
                        {ingredients.length > 0 && <div className="row iconos"><div className="col-12"><button type="button" className={classbutton}><FontAwesomeIcon icon={faList}  color= {colorhex}/></button><span>{ingredients}</span></div></div>}
                        <div className="row iconos">
                            <div className="col-12">
                                <button type="button" className={classbutton}><FontAwesomeIcon icon={faUser}   color= {colorhex}/></button><span>{lang.addedby + " " + userBeer}</span>              
                            </div>
                        </div>

                        <div className="row iconos">    
                            <div className="col-12 col-lg-6">
                                <div className='parent' ref={this.myRef}>
                                { numimages > 0 &&
                                <AwesomeSlider bullets={false} transitionDelay={100} organicArrows={true} mobileTouch={true} className={"sliderstylesbeer"}>
                                    {data}
                                </AwesomeSlider>
                                }
                                
                            {/*<span className="addFotos" style={{color:colorhex}} onClick={() =>this.props.history.push(urlphoto)}><FontAwesomeIcon size="1x" color={colorhex} icon={faImages} /> <label>{lang.add_photo}</label></span>*/}
                                
                                </div>
                                
                            </div>  
                        </div>
                        {this.state.showComments === true &&
                        <div className="comments container">
                            <hr></hr>
                            <div className="row">
                                <div className="commenttitle col-6">{lang.comments}<span className="ratingscomments" onClick={this.ShowComments}><label>{numratings+ " "+ lang.ratings}</label></span></div>
                                <div className="col-6" style={{cursor:'pointer'}}><FontAwesomeIcon size="3x" color={colorhex } icon={faSortUp} onClick={this.ShowComments}/></div>
                            </div>
                            
                            <div className="row">
                                {beer.comments.map((comment) => {
                                            return <Comment
                                            key={comment.beerCommentID}
                                            comment={comment}
                                            lang={lang}
                                            color={colorhex}>
                                            </Comment>                                            
                                })}
                            
                            </div>
                            {user !== null && 
                            <form className="well form-horizontal sendrating" onSubmit={this.mySubmitHandler}  id="contact_form">
                                <div className="form-group">
                                <label className="col-md-6 control-label" ><b>{user.username + " "}</b> {capitalize(lang.your_comment)}</label> 
                                    <div className="col-md-6 inputGroupContainer">
                                    <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                                    <FontAwesomeIcon color={this.state.rating > 0 ? colorhex : '#bbb'} icon={faStar} onClick={this.myClickStar(1)}/>
                                    <FontAwesomeIcon color={this.state.rating > 1 ? colorhex : '#bbb'} icon={faStar} onClick={this.myClickStar(2)}/>
                                    <FontAwesomeIcon color={this.state.rating > 2 ? colorhex : '#bbb'} icon={faStar} onClick={this.myClickStar(3)}/>
                                    <FontAwesomeIcon color={this.state.rating > 3 ? colorhex : '#bbb'} icon={faStar} onClick={this.myClickStar(4)}/>
                                    <FontAwesomeIcon color={this.state.rating > 4 ? colorhex : '#bbb'} icon={faStar} onClick={this.myClickStar(5)}/>
                                    </div>
                                </div>

                                <div className="form-group">
                                <label className="col-md-6 control-label" >{capitalize(lang.comment)}</label> 
                                    <div className="col-md-6 inputGroupContainer">
                                        <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                                        <textarea rows="3" name="comment" onChange={this.myChangeHandler} className="form-control">{this.state.ingredients}</textarea>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-md-6 control-label"></label>
                                    <div className="col-md-6"><button type="submit" className="btn btn-secondary btn-sm" >{lang.submit.toUpperCase()} <span className="glyphicon glyphicon-send"></span></button>
                                    </div>
                                </div>
                                
                                {this.state.enviado == true &&
                                <div class={"alert alert-"+clase}>
                                    <strong>
                                    {clase === "danger" &&
                                        lang.comment_error_title
                                    }
                                    {clase === "success" &&
                                        lang.comment_sent_title
                                    }
                                    </strong>
                                    {clase === "danger" &&
                                        lang.comment_error
                                    }
                                    {clase === "success" &&
                                        lang.comment_sent
                                    }
                                
                                </div>
                                }
                            </form>
                            }
                            {user === null && 
                                //añadir url de la cerveza para que no la tenga que volver a buscar
                                <a href={"login?href=place/"+beer.placeID+"/"+beerID}>{capitalize(lang.login_comment)}</a>
                            }
                        
                        </div>
                        }
                        
                    </div>
                    }
             </div>
        );
    }
}
export default withRouter(BeerModal);