import React, { Component } from "react";
import { urlWS, GetParamsUserAgent } from '../functions/ifelse.js';
import { capitalize } from '../functions/ifelse.js';
import { getLang } from '../functions/ifelse.js';

var lang = null;
var mensaje = "";
var classCompleted = "alert alert-danger";
var button_cont;
export default class Signup extends Component {

    constructor(props){
        super(props);
        this.state ={
            username:null,
            email:null,
            password1:null,
            password2:null,
            datebirth1:null,
            datebirth2:null,
            datebirth3:null,
            owner:null,
            loading:false,
            completed:false
        }

        lang = getLang();

        button_cont = <a align="center" href="/signup" className="alert-link">{lang.try_again}</a>;
        
    }

    convertToParamas(objeto){ //operations
        return {
            'username':objeto.username,
            'email':objeto.email,
            'password':objeto.password1,
            'datebirth':objeto.datebirth3+"/"+objeto.datebirth2+"/"+objeto.datebirth1,
            'owner':objeto.owner
        }
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if(nam == "owner"){
            var va = 0;
            if(event.target.checked)
                va = 1;
            this.setState({owner: va});
        }
        else{
            this.setState({[nam]: val});    
        }
      }

    componentDidMount(){
        
    }

    calculateAge(birthday) { // birthday is a date
        birthday = new Date(birthday);
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    mySubmitHandler = (event) => {
        event.preventDefault();

        if(this.state.password1 != this.state.password2){
            alert("Las contraseñas deben ser iguales");
            return;
        }

        var params = this.convertToParamas(this.state);
        var alerta = lang.menor;
        
        if(this.calculateAge(params.datebirth) < 18){
            alert(alerta);
            return;
        }

        //aquí debemos de llamar al ws para crear el local y pasar this.state
        
        const self = this;
        self.setState({ 
            loading:true
        });  
        
        (async () => {
            console.log(urlWS+'user/create.php');
            let data = GetParamsUserAgent();
            const rawResponse = await fetch(urlWS+'user/create.php'+data, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Content-Type':'application/x-www-form-urlencoded' //sin esta línea da error
              },
              body: JSON.stringify(params)
            });
            const response = await rawResponse.json();          
            var id = response.id;
            var message = lang.error;
            if(parseInt(id) > 0){
                button_cont = <a align="center" href="/login?href=./?f=1" className="alert-link">{lang.continue}</a>;
                mensaje = lang.user_created;
                classCompleted = "alert alert-success";
                self.setState({ 
                    loading:false,
                    completed:true
                });  
            }else if (id == -2){
                mensaje = lang.email_exists;
                self.setState({ 
                    loading:false,
                    completed:true
                });  
            }     
            else if (id == -3){
                mensaje = lang.username_exists;
                self.setState({ 
                    loading:false,
                    completed:true
                });  
            }     
            else{
                mensaje = message;
                self.setState({ 
                    loading:false,
                    completed:true
                });  
            }            
          })();
      }

    render() {
        if(this.state.loading == true){
            return(
            <div className="loading_center">
                <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-secondary" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-success" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-danger" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-warning" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-info" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-light" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-dark" role="status">
                <span className="sr-only">Loading...</span>
                </div>
            </div>
            );
        }
        else if(this.state.completed == true){
            return(
                <div className="loading_center">
                    <div className={classCompleted}>
                        <div>{mensaje}</div>                        
                        {button_cont}
                    </div>
                </div>
                );
        }
        else{
        return (
            <div className="auth-wrapper">
                <div className="auth-inner col-12 col-lg-4">
                    <form  onSubmit={this.mySubmitHandler}>
                        <h3>{lang.signup}</h3>

                        <div className="form-group">
                            <label>{capitalize(lang.username)}</label>
                            <input type="text" onChange={this.myChangeHandler} required name="username" className="form-control" placeholder={capitalize(lang.username)} />
                        </div>

                        <div className="form-group">
                            <label>{capitalize(lang.email)}</label>
                            <input type="email" onChange={this.myChangeHandler} required name="email" className="form-control" placeholder={lang.enter+" "+lang.email}/>
                        </div>

                        <div className="form-group">
                            <label>{capitalize(lang.password)}</label>
                            <input type="password" pattern=".{6,}" onChange={this.myChangeHandler} required name="password1" className="form-control" placeholder={lang.enter+" "+lang.password} />
                        </div>
                        <div className="form-group">
                            <label>{capitalize(lang.repeat_password)}</label>
                            <input type="password" pattern=".{6,}" onChange={this.myChangeHandler} required name="password2"  className="form-control" placeholder={capitalize(lang.repeat_password)} />
                        </div>

                        <div className="form-group">
                            <label>{capitalize(lang.birthdate)}</label>
                            <div className="row">
                                <div className="col-3">
                                    <input type="text" onChange={this.myChangeHandler} name="datebirth1" placeholder="01" pattern="[0-9]{2}" required className="form-control" />
                                </div>
                                <div className="col-1" style={{lineHeight:'30px'}}>
                                    /
                                </div>    
                                <div className="col-3">
                                    <input type="text" onChange={this.myChangeHandler} name="datebirth2" placeholder="12" pattern="[0-9]{2}" required className="form-control" />
                                </div>
                                <div className="col-1" style={{lineHeight:'30px'}}>
                                    /
                                </div>    
                                <div className="col-3">
                                    <input type="text" onChange={this.myChangeHandler} name="datebirth3" placeholder="2000" pattern="[0-9]{4}" required className="form-control" />
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            
                            <label >{capitalize(lang.owner_are)}</label>
                            <input style={{marginLeft:'10px'}} onChange={this.myChangeHandler} type="checkbox" name="owner"  />
                        </div>

                        <button type="submit" className="btn btn-primary btn-block">{lang.signup}</button>
                        <p className="forgot-password text-right">
                            {lang.already_register} <a href="/login">{lang.login}</a>
                        </p>
                    </form>
            </div>
            </div>
        );
        }
    }
}