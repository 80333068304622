import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWineBottle, faBeer,  faHandPaper,  faBuilding} from '@fortawesome/free-solid-svg-icons'
import { Icontypebar } from '../../functions/ifelse';
var lang;

class Legend extends React.Component{
    constructor(props){
        super(props);
        lang=this.props.lang;
    }    
    
    render(){
            //LEYENDA
            var tiposbar = [];
            Object.keys(lang.types_bar).map(function(key) {
                tiposbar.push(<div key={key} className="tipobar col-3">
                            <div className="row">
                                <button type="button" className="btn btn-circle btn-ligth"><FontAwesomeIcon color="#212529" icon={Icontypebar(key)} /></button>
                            </div>
                            <div className="row">
                                <p>{lang.types_bar[key]}</p>
                            </div>     
                        </div>);
            })

            const classbutton = "btn btn-circle btn-dark";
            var recipient= [];
            var made=[];
            var infobeer = [];
            

            recipient.push(<div key="beer" className="tipobar col-3">
                            <div className="row">
                            <button type="button" className={classbutton}><FontAwesomeIcon icon={faBeer} /></button>
                            </div>
                            <div className="row">
                                <p>{lang.beer_recipient.draft}</p>
                            </div>     
                        </div>);

            recipient.push(<div  key="wine" className="tipobar col-3">
                            <div className="row">
                            <button type="button" className={classbutton}><FontAwesomeIcon icon={faWineBottle} /></button>
                            </div>
                            <div className="row">
                                <p>{lang.beer_recipient.bottle}</p>
                            </div>     
                            </div>);

            made.push(<div  key="hand" className="tipobar col-3">
            <div className="row">
                <button type="button" className={classbutton}><FontAwesomeIcon icon={faHandPaper} /></button>
            </div>
            <div className="row">
                <p>{lang.beer_made.craft}</p>
            </div>     
            </div>);
            
            made.push(<div  key="comercial" className="tipobar col-3">
                <div className="row">
                    <button type="button" className={classbutton}><FontAwesomeIcon icon={faBuilding} /></button>
                </div>
                <div className="row">
                    <p>{lang.beer_made.comercial}</p>
                </div>     
                </div>);

                

            infobeer.push(<div  key="price" className="tipobar col-3">
            <div className="row">
                <button type="button" className={classbutton}>2.6€</button>
            </div>
            <div className="row">
                <p>{lang.price}</p>
            </div>     
            </div>);
            
            infobeer.push(<div  key="volume" className="tipobar col-3">
                        <div className="row">
                            <button type="button" className={classbutton}>0.5l</button>
                        </div>
                        <div className="row">
                            <p>{lang.volume_l}</p>
                        </div>     
                        </div>);


            var colors = [];
            Object.keys(lang.beer_colors).map(function(key) {
                colors.push(<div key={key} className="tipobar col-3">
                            <div className="row">
                                <button type="button" className="btn btn-ligth"><FontAwesomeIcon color={lang.beer_colors_hex[key]} icon={faBeer} /></button>
                            </div>
                            <div className="row">
                                <p style={{color: lang.beer_colors_hex[key]}}>{lang.beer_colors[key]}</p>
                            </div>     
                        </div>);
            })
            
        return (
            <div key="locals" className="container">
                <br/>
                <span className="tipos_local">{lang.type_bar}</span>
                <div className="row">{tiposbar}</div>
                <br/>
                <span className="tipos_local">{lang.recipient_beer}</span>
                <div className="row">{recipient}</div>
                <br/>
                <span className="tipos_local">{lang.made_beer}</span>
                <div className="row">{made}</div>
                <br/>
                <span className="tipos_local">{lang.info_beer}</span>
                <div className="row">{infobeer}</div>
                <br/>
                <span className="tipos_local">{lang.colors}</span>
                <div className="row">{colors}</div>
            </div>
        );
    }
}
export default Legend;