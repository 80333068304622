import React from "react"
import "../../css/marker.css"
import { Icontypebar } from '../../functions/ifelse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils } from '@fortawesome/free-solid-svg-icons'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import {faCompactDisc} from '@fortawesome/free-solid-svg-icons'
import {faGlassMartiniAlt} from '@fortawesome/free-solid-svg-icons'
import {faBeer} from '@fortawesome/free-solid-svg-icons'
import {faWineGlassAlt} from '@fortawesome/free-solid-svg-icons'


class Marker extends React.Component{
    render(){
        let typebar = Icontypebar(this.props.type);

        let clase='btn-info';
        if(this.props.selected){
            clase= 'btn-success';
        }


        return (
            // si el onclick no tiene parámetros llama directamente a esa prop que debe ser una función en la clase padre
            //handlerMarker es una propiedad más del componente marker
        
            <button onClick = {this.props.handlerMarker} type="button" className={"centericon btn btn-xs btn-circle " + clase}><FontAwesomeIcon icon={typebar} size="1x"/></button>
        

        );
    }
}
export default Marker;