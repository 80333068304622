import {withRouter} from 'react-router-dom'
import React from "react";
import GoogleMapReact from 'google-map-react'
import { Component } from "react";
import '../css/create.css';
import MultipleImageUploadComponent from './multiple-image-upload.component';
import GoogleImage from './google-image';
import { urlWS,apikeywbm, GetTipo, GetParamsUserAgent } from '../functions/ifelse.js';
import { getLang } from '../functions/ifelse.js';
import { capitalize } from '../functions/ifelse.js';
import { CheckLogin } from '../functions/ifelse.js';
import {Link} from 'react-router-dom'
let params = null;
let center ={lat: 0, lng:0};
var leng;
var url_add;

var types_bar = null;
var mensaje = "";
var classCompleted = "alert alert-danger";
var followmessage = "";
var userID, lang;


const mapOptions = maps => {
    return {
      //esto muestra los marcadores de google (restaurantes ....)
      styles: [
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'on' }],
        }
        ]
    };
  };

class Create extends Component {
    
    constructor(props){
        console.log("constructor");
        super(props);
        
        userID = CheckLogin(props);

        this.state ={
            email:'',
            name:'',
            address:'',
            type:'restaurant',
            gPlaceID:'',
            priceLevel:'',
            gRating:'',
            web:'',
            phone:'',
            openingHours:'',
            happyHour:'',
            arrayGooglePhotos:[],
            arrayLocalPhotos:[],
            loading:false,
            completed:false,
            gcompleted:false
        }
        this.handleStateChangeGP  = this.handleStateChangeGP.bind(this);
        this.handleStateChangeLP  = this.handleStateChangeLP.bind(this);
        
        params = this.props.match.params;
        console.log(params.lat);
        center.lat = parseFloat(params.lat);
        center.lng = parseFloat(params.lng);

        console.log(center);

        lang = getLang();
        
        leng = lang.lang;
        types_bar = lang.types_bar;
        url_add = "/"+leng;
        followmessage = lang.home;
    }

    componentDidMount(){
        console.log("mount");
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        console.log('error')
        this.setState({
          error: error,
          errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
      }

    doEvent( obj, event ) {
        /* Created by David@Refoua.me */
        var event = new Event( event, {target: obj, bubbles: true} );
        return obj ? obj.dispatchEvent(event) : false;
    }

    onLoadApi = ({map, maps}) => {
        //esto lo hacemos porque se pierde el foco
            if(params.id != null){
                const self = this;
                
                console.log("onloadapi");
                var placesService  = new maps.places.PlacesService(map);
                
                
                placesService.getDetails({
                    placeId: params.id,
                    fields: ["name", "formatted_address","type", "price_level", "rating","website","formatted_phone_number","opening_hours", "photo"]
                }, function(place, status) {
                    if (status === maps.places.PlacesServiceStatus.OK) {
                        ///CALCULO HORARIO
                        var openH = "";
                        if(place.opening_hours){
                            place.opening_hours.weekday_text.map((answer, i) => {     
                                openH += answer;
                                if(i < place.opening_hours.weekday_text.length - 1)
                                    openH += "\n";
                            })
                        }
                        ///CALCULO HORARIO FIN

                        //CALCULO ARRAY PHTOS
                        var arrayG = [];
                        var max = 6;
                        var j = 0;
                        if(place.photos != null){
                            place.photos.map((answer, i) => {     
                                    if(j == max)
                                        return;
                                    else
                                        arrayG.push(place.photos[i].getUrl());
                                    j++;
                            })
                        }
                        //CALCULO ARRAY PHTOS FIN

                        var tipo = GetTipo(place.types);

                        //SET STATE
                        console.log(place);
                        self.setState({ 
                            name:place.name,
                            address:place.formatted_address,
                            type:tipo,
                            gPlaceID:params.id,
                            priceLevel:place.price_level,
                            gRating:place.rating,
                            web:place.website,
                            phone:place.formatted_phone_number,
                            openingHours:openH,
                            arrayGooglePhotos:arrayG,
                            gcompleted:true});  
                        //SET STATE FIN

                        //CALCULO EMAIL
                        if(place.website){
                            let data = GetParamsUserAgent();
                            fetch(urlWS+'place/getemail.php'+data+'&web='+place.website)
                            .then(res => res.json())
                            .then(
                                (result) => {
                                    self.setState({ email: result });
                                },
                                (error) => {
                                console.log(error);
                                }
                            )
                        }
                        //CALCULO EMAIL FIN
                    }
                });
        }
        else{
            this.setState({ gcompleted:true});
        }
    }

    convertToParamas(objeto){ //operations
       return {
        'lat':params.lat,
        'lng':params.lng,
        'name':objeto.name,
        'address':objeto.address,
        'type':objeto.type,
        'gPlaceID':objeto.gPlaceID,
        'priceLevel':objeto.priceLevel,
        'gRating':String(objeto.gRating),
        'web':objeto.web,
        'phone':objeto.phone,
        'openingHours':objeto.openingHours,
        'happyHour':objeto.happyHour,
        'arrayGooglePhotos':objeto.arrayGooglePhotos,
        "userID":userID
       }
    }

    //,    'arrayGooglePhotos':objeto.arrayGooglePhotos,    'arrayLocalPhotos':objeto.arrayLocalPhotos

    mySubmitHandler = (event) => {
        event.preventDefault();
        //aquí debemos de llamar al ws para crear el local y pasar this.state
        var params = this.convertToParamas(this.state);
        console.log(params);
        const self = this;
        self.setState({ 
            loading:true
        });  
        
        (async () => {
            let data3 = GetParamsUserAgent();
            const rawResponse = await fetch(urlWS+'place/create.php'+data3, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Content-Type':'application/x-www-form-urlencoded' //sin esta línea da error
              },
              body: JSON.stringify(params)
            });
            const response = await rawResponse.json();          
            var id = response.id;
            var message = lang.error;
            if(parseInt(id) > 0){
                url_add = "/add/"+id+"/"+this.state.name;
                message = lang.place_created;
                //////////////////////////////////////////////////////LOCAL IMAGES//////////////////////////////////
                let data = new FormData();
                let numimages = 0;
                for (let i = 0 ; i < this.state.arrayLocalPhotos.length ; i++) {   
                    if(this.state.arrayLocalPhotos[i]){
                        data.append('files[]', this.state.arrayLocalPhotos[i], this.state.arrayLocalPhotos[i].name);
                        numimages++;
                    }
                }
                data.append('userID', userID);
                if(numimages > 0){
                    (async () => {
                        let data4 = GetParamsUserAgent();
                        const rawResponse = await fetch(urlWS+'place/addphotos.php'+data4+'&id='+id, {
                          method: 'POST',
                          body: data
                        });
                        const response = await rawResponse.json();
                        
                        var res = response.id;
                        if(res <= 0){
                            message += lang.place_addphotos_error;
                        }
    
                        mensaje = message;
                        classCompleted = "alert alert-success";
                        followmessage = lang.add_beers;
                        self.setState({ 
                            loading:false,
                            completed:true
                        });  
                        
                      })();
                }
                else{
                    mensaje = message;
                    classCompleted = "alert alert-success";
                    followmessage = lang.add_beers;
                    self.setState({ 
                        loading:false,
                        completed:true
                    });  
                }
                //////////////////////////////////////////////////////FIN LOCAL IMAGES//////////////////////////////////

            }else{
                self.setState({ 
                    loading:false,
                    completed:true
                });  
            }            
          })();
      }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
      }

    handleStateChangeGP (url) {
        //Esta función es llamada desde el componente hijo google-image.js y modifica el array de imágenes que a su vez es props del hijo
        var arrayG = this.state.arrayGooglePhotos;
        var index = arrayG.indexOf(url); // Let's say it's Bob.
        delete  arrayG[index];
        this.setState({ arrayGooglePhotos: arrayG })
    }

    handleStateChangeLP(files) {
        this.setState({arrayLocalPhotos:files})
    }

    render() {
        if(this.state.loading == true){
            return(
            <div className="loading_center">
                <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-secondary" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-success" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-danger" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-warning" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-info" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-light" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-dark" role="status">
                <span className="sr-only">Loading...</span>
                </div>
            </div>
            );
        }
        else if(this.state.completed == true){
            return(
                <div className="loading_center">
                    <div className={classCompleted}>
                        <div>{mensaje}</div>                        
                        <Link to={url_add}>{followmessage}</Link>
                    </div>
                </div>
                );
        }
        else{
            return (
                <div className="container h-100">
                    <div id="map" className="mapcreate col-md-6">
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: apikeywbm, libraries: ["places"],language: 'en',
                            region: 'en' }}
                            center={center}
                            zoom={16}
                            placesLibrary={true}
                            onGoogleApiLoaded={ this.onLoadApi.bind(this) }
                            options={mapOptions}
                            yesIWantToUseGoogleMapApiInternals
                            >                        
                        </GoogleMapReact>
                    </div>
                    
                    { this.state.gcompleted == true &&
                    <div>
                    
                    
                    

                    <form className="well form-horizontal" onSubmit={this.mySubmitHandler}  id="contact_form">
                    <fieldset>
                    
                    <div className="form-group col-md-6 margin-top-20"><h3><b>{lang.add_local} - {this.state.name}</b></h3></div>
                    
                    
                    <div className="form-group">
                    <label className="col-md-6 control-label">{capitalize(lang.name)}</label>  
                    <div className="col-md-6 inputGroupContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                            <input required name="name" value={this.state.name} onChange={this.myChangeHandler} className="form-control"  type="text"/>
                        </div>
                    </div>
                    </div>
                    
                    
                    <div className="form-group">
                    <label className="col-md-6 control-label" >{capitalize(lang.address)}</label> 
                        <div className="col-md-6 inputGroupContainer">
                        <div className="input-group">
                    <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                    <input name="address" value={this.state.address} onChange={this.myChangeHandler} className="form-control"  type="text"/>
                        </div>
                    </div>
                    </div>
                    
                    <div className="form-group"> 
                    <label className="col-md-6 control-label">{capitalize(lang.type)}</label>
                        <div className="col-md-6 selectContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                        <select name="type" className="form-control selectpicker" onChange={this.myChangeHandler}>
                            {Object.keys(types_bar).map(key => 
                            <option selected={key == this.state.type} key={key} value={key}>{types_bar[key]}</option>                          
                            )}
                        </select>
                    </div>
                    </div>
                    </div>
                    
                    <input name="lat" value={params.lat} className="form-control"  type="hidden"/>
                    <input name="lng" value={params.lng} className="form-control"  type="hidden"/>
                    <input name="gPlaceID" value={params.id} className="form-control"  type="hidden"/>
                    <input name="priceLevel" value={this.state.price_level} className="form-control"  type="hidden"/>
                    <input name="gRating" value={this.state.rating} className="form-control"  type="hidden"/>


                    <div className="form-group"> 
                    <label className="col-md-6 control-label">{capitalize(lang.web)}</label>
                        <div className="col-md-6 selectContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                            <input name="web" value={this.state.web} onChange={this.myChangeHandler} className="form-control"  type="text"/>
                    </div>
                    </div>
                    </div>

                    <div className="form-group"> 
                    <label className="col-md-6 control-label">{capitalize(lang.phone)}</label>
                        <div className="col-md-6 selectContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                            <input name="phone" value={this.state.phone} onChange={this.myChangeHandler} className="form-control"  type="text"/>
                    </div>
                    </div>
                    </div>

                    <div className="form-group"> 
                    <label className="col-md-6 control-label">{capitalize(lang.email)}</label>
                        <div className="col-md-6 selectContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                            <input name="email" value={this.state.email} className="form-control" onChange={this.myChangeHandler}  type="text" ref="email"/>
                    </div>
                    </div>
                    </div>

                    <div className="form-group"> 
                    <label className="col-md-6 control-label">{capitalize(lang.openingHours)}</label>
                        <div className="col-md-6 selectContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                            <textarea rows="7" name="openingHours" onChange={this.myChangeHandler} className="form-control">{this.state.openingHours}</textarea>
                    </div>
                    </div>
                    </div>

                    <div className="form-group"> 
                    <label className="col-md-6 control-label">{capitalize(lang.happyHour)}</label>
                        <div className="col-md-6 selectContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                            <textarea rows="3" name="happyHour" onChange={this.myChangeHandler} className="form-control">{this.state.happyHour}</textarea>
                    </div>
                    </div>
                    </div>
                
                    <div className="form-group col-md-6">
                        <div className="card">
                        <div className="card-header">
                            {capitalize(lang.add_photos)}
                        </div>
                        <div className="card-body">
                            <MultipleImageUploadComponent handleStateChangeLP ={this.handleStateChangeLP }/>
                        </div>
                        </div>
                    </div>

                    {params.id != null && <div className="form-group col-md-6">
                        <div className="card">
                        <div className="card-header">
                            {capitalize(lang.add_photos_g)}
                        </div>
                        <div className="card-body">
                            <GoogleImage fileArray={this.state.arrayGooglePhotos} handleStateChangeGP ={this.handleStateChangeGP } />
                        </div>
                        </div>
                    </div>
                    }   

                    <div className="form-group">
                        <label className="col-md-6 control-label"></label>
                        <div className="col-md-6"><button type="submit" className="btn btn-warning" >{lang.submit.toUpperCase()} <span className="glyphicon glyphicon-send"></span></button>
                        </div>
                    </div>
                    
                    </fieldset>
                    </form>
                    </div>
                    }

                    
                    
                    
                </div>
            );
        }
    }
};

export default withRouter(Create);