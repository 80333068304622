import {
    BrowserRouter as Router,
    HashRouter,
    withRouter,
    Link,
    Route,
    Switch
  } from 'react-router-dom'
import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import Main from "./main";
import Create from "./create";
import Login from "./login";
import LoginAuto from "./loginauto";
import Profile from "./profile";
import Add from "./add";
import Edit from "./edit";
import EditBeer from "./editbeer";
import favicon from '../images/wbm_ico.png';
import Signup from "./signup";
import { getLang, getLangCode } from '../functions/ifelse.js';
import {Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faSignInAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { Redirect } from 'react-router-dom'
import { CookieBanner } from "react-cookie-law-customizable";
import ModalUser from './modaluser';
import ReactGA from "react-ga"
import {
    osName,osVersion,browserName,browserVersion,deviceType
  } from "react-device-detect";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/profile.css';

const history = require('history').createHistory;
let user,userID,lang, langcode;
var redirect=false;

let uid = null;
let vbd = 0;
let ejecutado = false;

class App extends Component {
    constructor(props){
        super(props);
        this.state ={
            user:null,
            userID:null,
            modalIsOpen:false   
        }
        this.logout = this.logout.bind(this);
        this.updateParent = this.updateParent.bind(this);
        lang = getLang();
        langcode = getLangCode();
        
    }

    toggleShowModal(){
        this.setState({modalIsOpen: !this.state.modalIsOpen})
    }

    cerrarModal = ()=>{
        this.setState({modalIsOpen: !this.state.modalIsOpen})
    }

    clickUserButton(){
        this.setState((state) => {
            // Importante: lee `state` en vez de `this.state` al actualizar.
            return {modalIsOpen:true}
        });
    }
    

    componentDidMount() {
        user = localStorage.getItem('user') ;
        userID = localStorage.getItem('userID') ;
        if(localStorage.getItem('vbd'))
            vbd = localStorage.getItem('vbd');
        if(localStorage.getItem('uid'))
            uid = localStorage.getItem('uid');


        localStorage.setItem('uid',uid);
        localStorage.setItem('vbd',vbd);    

       


        this.setState({user: user,userID: userID});
    }

    logout = (event) => {
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('user');
        localStorage.removeItem('email');
        localStorage.removeItem('userID');
        redirect= true ;
        this.setState({user: null});        
        //<Redirect to='/login' />
    }

    LinkToProfile(){
        console.log(history)
        history.replace("/profile/"+ userID);
    }

    LinkToAnotherLang(lang){
        window.location.assign('https://'+lang+'.worldbeermap.com'+window.location.pathname);  
    }

    updateParent(){
        console.log("UPDATE");
        user = localStorage.getItem('user') ;
        userID = localStorage.getItem('userID') ;
        this.setState({user: user, userID: userID});
        
    }

    

    render() {
        if(!ejecutado){
            //user agent
            console.log("user agent");
            localStorage.setItem('osName',osName);
            localStorage.setItem('osVersion',osVersion);
            localStorage.setItem('browserName',browserName);
            localStorage.setItem('browserVersion',browserVersion);
            localStorage.setItem('deviceType',deviceType);
            ejecutado = true;
        } 
        //Google analitics
        if (window.ga) {
            ReactGA.initialize('G-HVY0LZCJQL');
            ReactGA.pageview(window.location.pathname);
        }

        const meta = <Helmet>
            <title>{lang.meta_title}</title>
            <meta name="title" content={lang.meta_title} />
            <meta name="description" content={lang.meta_description} />
            <meta name="keywords" content={lang.meta_keys} />

            <link rel="canonical" href="https://worldbeermap.com" />
            <link href="https://worldbeermap.com" rel="alternate"  hreflang="en" />
            <link href="https://en.worldbeermap.com" rel="alternate"  hreflang="en" />
            <link href="https://es.worldbeermap.com" rel="alternate"  hreflang="es" />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@worldbeermap"/>
            <meta name="twitter:title" content={lang.meta_title} />
            <meta name="twitter:description" content={lang.meta_description} />

            <meta property="og:title" content={lang.meta_title} />
            <meta property="og:site_name" content="WorldBeerMap" />
            <meta property="og:description" content={lang.meta_description} />
            <meta property="og:image" content="https://worldbeermap.com/static/media/wbm_ico.1b69a9fe.png" />
        </Helmet> ; 

          var htmlredirect = "";
          if(redirect){
            redirect = false;
            htmlredirect = <Redirect to='/login' />;
        }           


        var cookiescode = <div className="cookies">
            <CookieBanner
            styles={{
                dialog: { backgroundColor: "#ddd",bottom:"0",zIndex:"200000" }
            }}
            message={lang.cookies}
            onAccept={() => {}}
            showStatisticsOption = {false}
            showMarketingOption = {false}
            showPreferencesOption ={false}
            necessaryOptionText = "Necessary cookies"
            policyLink ="cookies.html"
            />
        </div>;

        
        if(langcode == 'es'){
            var changeLang = <span className='click' onClick={()=>this.LinkToAnotherLang('en')}>EN</span>
        }
        else{
            var changeLang = <span className='click' onClick={()=>this.LinkToAnotherLang('es')}>ES</span>
        }
        var navcode = <nav className="navbar p-0 shadow text-dark" style={{backgroundColor:'white'}}>
            <Link className="navbar-brand align-middle ml-1 text-dark" to="/"><img width="30px" src={favicon}/> <span className="align-middle">WORLD BEER MAP<small className="text-warning"> beta</small></span></Link>
            
            
            {this.state.user &&
                /*<ul className="navbar-nav px-6 list-group list-group-horizontal">                        
                <li className="list-group-item">
                    <Link className="nav-link" to={"/profile/"+this.state.userID}>{user}</Link>
                </li>
                <li className="list-group-item">
                <a className="nav-link username" onClick={this.logout} style={{cursor:'pointer'}} >
                    <FontAwesomeIcon icon={faSignOutAlt}/>
                </a>
                </li>
                
                </ul>*/
                <ul className="navbar-nav px-3 list-group list-group-horizontal">                        
                    <li className="list-group-item">
                        {changeLang}
                    </li>
                    <li style={{cursor:'pointer'}} className="list-group-item">
                        <FontAwesomeIcon size="2x" onClick={() => this.clickUserButton()} icon={faUserCircle}/>
                    </li>
                </ul>
            }
            {!this.state.user &&
                <ul className="navbar-nav px-3 list-group list-group-horizontal">                        
                    <li style={{cursor:'pointer'}} className="list-group-item">
                        {changeLang}
                    </li>
                    <li style={{cursor:'pointer'}} className="list-group-item">
                        <Link to="/login"><FontAwesomeIcon icon={faSignInAlt}/></Link>
                    </li>
                </ul>
            }
        </nav>;

        var modalcode = <ModalUser lang={lang} abierto={this.state.modalIsOpen} close={this.cerrarModal}  LinkToProfile={this.LinkToProfile} handlerLogout={this.logout} toggleShowModalCallback={this.toggleShowModal.bind(this)}>
        </ModalUser>;


        var switchcode = <Switch>
            <Route exact path="/" component={Main} />
            <Route path="/place/:placeID/:beerID?" component={Main} />
            <Route path="/login" render={(props) => <Login {...props} action={this.updateParent} />} />
            <Route path="/signup" component={Signup} />
            <Route path="/loginma/:token/:placeID/:name" render={(props) => <LoginAuto {...props} action={this.updateParent} />}/>
            <Route path="/profile/:id" component={Profile} />
            <Route exact path="/create/:lat/:lng/:id?" component={Create} />
            <Route path="/add/:placeID/:name" component={Add} />
            <Route path="/edit/:placeID" component={Edit} />
            <Route path="/editbeer/:beerID" component={EditBeer} />
            <Route render={() => <h2 className="four-o-four">404 Page Not Found</h2>} />
        </Switch>;
    
        if(window.cordova){
            return(
                <HashRouter history={history}>
                    {cookiescode}
                    {navcode}
                    {modalcode}
                    {meta} 
                    {htmlredirect}
                    {switchcode}
                </HashRouter>   
            );
        }
        else{
            return(
                <Router history={history}>
                    {cookiescode}
                    {navcode}
                    {modalcode}
                    {meta}  
                    {htmlredirect}
                    {switchcode}
                </Router>   
            );
        }

        
    }
}

export default withRouter(App);