import React, { Component } from 'react';
import PropTypes from 'prop-types';
let lang = '';

class SearchBox extends Component {
  static propTypes = {
    mapsapi: PropTypes.shape({
      places: PropTypes.shape({
        SearchBox: PropTypes.func,
      }),
      event: PropTypes.shape({
        clearInstanceListeners: PropTypes.func,
      }),
    }).isRequired,
    placeholder: PropTypes.string,
    onPlacesChanged: PropTypes.func,
  };

  static defaultProps = {
    placeholder: 'search...',
    onPlacesChanged: null,
  };

  constructor(props) {
    super(props);
    lang=this.props.lang;
    this.searchInput = React.createRef();
  }

  componentDidMount() {
    const {
      mapsapi: { places },
    } = this.props;

    this.searchBox = new places.SearchBox(this.searchInput.current);
    this.searchBox.addListener('places_changed', this.onPlacesChanged);
  }

  componentWillUnmount() {
    const {
      mapsapi: { event },
    } = this.props;

    event.clearInstanceListeners(this.searchBox);
  }

  onPlacesChanged = () => {
    const { onPlacesChanged } = this.props;

    if (onPlacesChanged) {
      onPlacesChanged(this.searchBox.getPlaces());
    }

    var latlng = this.searchBox.getPlaces()[0].geometry.location;
    this.props.handlerPlaceChanged(latlng);
  };

  render() {
    const { placeholder } = this.props;

    return (
      <input className="form-control searchinput"
        ref={this.searchInput}
        placeholder={lang.search_city}
        type="text"
      />
    );
  }
}

export default SearchBox;