import {withRouter} from 'react-router-dom'
import React from "react";
import { Component } from "react";
import '../css/create.css';
import MultipleImageUploadComponent from './multiple-image-upload.component';
import { urlWS, GetParamsUserAgent } from '../functions/ifelse.js';
import { baseurl } from '../functions/ifelse.js';
import { capitalize } from '../functions/ifelse.js';
import { CheckLogin } from '../functions/ifelse.js';
import { getLang } from '../functions/ifelse.js';
import {Link} from 'react-router-dom'
let params = null;
var lang;

var types_bar = null;
var mensaje = "";
var classCompleted = "alert alert-danger";
var followmessage = "";
var userID;


class Edit extends Component {
    
    constructor(props){
        super(props);
        
        userID = CheckLogin(props);

        
        this.handleStateChangeLP  = this.handleStateChangeLP.bind(this);
        params  = this.props.match.params;

        this.state ={
            email:'',
            name:'',
            address:'',
            type:'',
            gPlaceID:'',
            priceLevel:'',
            gRating:'',
            web:'',
            phone:'',
            userID:null,
            openingHours:'',
            happyHour:'',
            images:[],
            imagesID:[],
            placeID:params.placeID,
            arrayLocalPhotos:[],
            isPropietario:false,
            loading:true,
            completed:false,
            gcompleted:false
        }

        lang = getLang();
        
        types_bar = lang.types_bar;
        followmessage = lang.home;
    }

    componentWillMount(){
        var self = this;
        let data = GetParamsUserAgent();
        fetch(urlWS+'place/read.php'+data+'&id='+params.placeID)
                .then(res => res.json())
                .then(
                    (place) => {
                        var isPropietario = false;
                        if(place.userID == userID){
                            isPropietario = true;

                        }
                        var ararryID = [];
                        place.images.map((image) => {
                            ararryID.push(image.placeImageID);
                        })

                        self.setState({ 
                            name:place.name,
                            address:place.address,
                            type:place.type,
                            web:place.web,
                            phone:place.phone,
                            openingHours:place.openingHours,
                            email:place.email,
                            gcompleted:true,
                            happyHour:place.happyHour,
                            images:place.images,
                            imagesID:ararryID,
                            userID:place.userID,
                            isPropietario:isPropietario,
                            loading: false});  
                    },
                    (error) => {
                    console.log(error);
                    }
                )
    }

    doEvent( obj, event ) {
        /* Created by David@Refoua.me */
        var event = new Event( event, {target: obj, bubbles: true} );
        return obj ? obj.dispatchEvent(event) : false;
    }


    convertToParamas(objeto){ //operations
       return {
        'name':objeto.name,
        'address':objeto.address,
        'type':objeto.type,
        'web':objeto.web,
        'email':objeto.email,
        'phone':objeto.phone,
        'openingHours':objeto.openingHours,
        'happyHour':objeto.happyHour,
        'images':objeto.imagesID,
        'placeID':objeto.placeID
       }
    }

    //,    'arrayGooglePhotos':objeto.arrayGooglePhotos,    'arrayLocalPhotos':objeto.arrayLocalPhotos

    mySubmitHandler = (event) => {
        event.preventDefault();
        //aquí debemos de llamar al ws para crear el local y pasar this.state
        var params = this.convertToParamas(this.state);
        console.log(params);
        const self = this;
        self.setState({ 
            loading:true
        });  
        
        (async () => {
            let data2 = GetParamsUserAgent();
            const rawResponse = await fetch(urlWS+'place/update.php'+data2, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Content-Type':'application/x-www-form-urlencoded' //sin esta línea da error
              },
              body: JSON.stringify(params)
            });
            const response = await rawResponse.json();          
            var id = response.id;
            var message = lang.error;
            if(parseInt(id) > 0){
                message = lang.place_created;
                //////////////////////////////////////////////////////LOCAL IMAGES//////////////////////////////////
                let data = new FormData();
                let numimages = 0;
                for (let i = 0 ; i < this.state.arrayLocalPhotos.length ; i++) {   
                    if(this.state.arrayLocalPhotos[i]){
                        data.append('files[]', this.state.arrayLocalPhotos[i], this.state.arrayLocalPhotos[i].name);
                        numimages++;
                    }
                }
                data.append('userID', userID);
                if(numimages > 0){
                    (async () => {
                        const rawResponse = await fetch(urlWS+'place/addphotos.php?id='+id, {
                          method: 'POST',
                          body: data
                        });
                        const response = await rawResponse.json();
                        
    
                        mensaje = lang.place_updated;
                        classCompleted = "alert alert-success";
                        followmessage = lang.home;
                        self.setState({ 
                            loading:false,
                            completed:true
                        });  
                        
                      })();
                }
                else{
                    mensaje = lang.place_updated;
                    classCompleted = "alert alert-success";
                    followmessage = lang.home;
                    self.setState({ 
                        loading:false,
                        completed:true
                    });  
                }
                //////////////////////////////////////////////////////FIN LOCAL IMAGES//////////////////////////////////

            }else{
                mensaje = lang.error;
                classCompleted = "alert alert-danger";
                followmessage = lang.home;
                
                self.setState({ 
                    loading:false,
                    completed:true
                });  
            }            
          })();
      }

      removePhoto(id){
            var imagesID = [];
            var arrayI = [];
            this.state.images.map((image) => {
                if(image.placeImageID != id){
                    arrayI.push(image);
                    imagesID.push(image.placeImageID);
                }
            })
            console.log(this.state.images);
            console.log(arrayI);
            this.setState({images:arrayI, imagesID:imagesID});
      }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});
      }


    handleStateChangeLP(files) {
        this.setState({arrayLocalPhotos:files})
    }

    Disabled(field){
        if(this.state.isPropietario)
            return false;
        else if(this.state[field] != null &&  this.state[field].length > 0){
            return true;
        }
        else{
            return false;
        }
    }

    render() {
        if(this.state.loading == true){
            return(
            <div className="loading_center">
                <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-secondary" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-success" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-danger" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-warning" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-info" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-light" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-dark" role="status">
                <span className="sr-only">Loading...</span>
                </div>
            </div>
            );
        }
        else if(this.state.completed == true){
            return(
                <div className="loading_center">
                    <div className={classCompleted}>
                        <div>{mensaje}</div>                        
                        <Link to={"/place/"+params.placeID}>{followmessage}</Link>
                    </div>
                </div>
                );
        }
        else{
            return (
                <div className="container h-100">                
                    
                    { this.state.gcompleted == true &&
                    <div>
                    
                    
                    

                    <form className="well form-horizontal" onSubmit={this.mySubmitHandler}  id="contact_form">
                    <fieldset>
                    
                    <div className="form-group col-md-6 margin-top-20"><h3><b>{lang.edit_local} - {this.state.name}</b></h3></div>
                    
                    
                    <div className="form-group">
                    <label className="col-md-6 control-label">{capitalize(lang.name)}</label>  
                    <div className="col-md-6 inputGroupContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                            <input disabled = {(this.Disabled("name"))? "disabled" : ""} required name="name" value={this.state.name} onChange={this.myChangeHandler} className="form-control"  type="text"/>
                        </div>
                    </div>
                    </div>
                    
                    
                    <div className="form-group">
                    <label className="col-md-6 control-label" >{capitalize(lang.address)}</label> 
                        <div className="col-md-6 inputGroupContainer">
                        <div className="input-group">
                    <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                    <input name="address" disabled = {(this.Disabled("address"))? "disabled" : ""} value={this.state.address} onChange={this.myChangeHandler} className="form-control"  type="text"/>
                        </div>
                    </div>
                    </div>
                    
                    <div className="form-group"> 
                    <label className="col-md-6 control-label">{capitalize(lang.type)}</label>
                        <div className="col-md-6 selectContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                        <select disabled = {(this.Disabled("type"))? "disabled" : ""} name="type" className="form-control selectpicker" onChange={this.myChangeHandler}>
                            {Object.keys(types_bar).map(key => 
                            <option selected={key == this.state.type} key={key} value={key}>{types_bar[key]}</option>                          
                            )}
                        </select>
                    </div>
                    </div>
                    </div>


                    <div className="form-group"> 
                    <label className="col-md-6 control-label">{capitalize(lang.web)}</label>
                        <div className="col-md-6 selectContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                            <input disabled = {(this.Disabled("web"))? "disabled" : ""} name="web" value={this.state.web} onChange={this.myChangeHandler} className="form-control"  type="text"/>
                    </div>
                    </div>
                    </div>

                    <div className="form-group"> 
                    <label className="col-md-6 control-label">{capitalize(lang.phone)}</label>
                        <div className="col-md-6 selectContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                            <input name="phone" disabled = {(this.Disabled("phone"))? "disabled" : ""} value={this.state.phone} onChange={this.myChangeHandler} className="form-control"  type="text"/>
                    </div>
                    </div>
                    </div>

                    <div className="form-group"> 
                    <label className="col-md-6 control-label">{capitalize(lang.email)}</label>
                        <div className="col-md-6 selectContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                            <input disabled = {(this.Disabled("email"))? "disabled" : ""} name="email" value={this.state.email} className="form-control" onChange={this.myChangeHandler}  type="text" ref="email"/>
                    </div>
                    </div>
                    </div>

                    <div className="form-group"> 
                    <label className="col-md-6 control-label">{capitalize(lang.openingHours)}</label>
                        <div className="col-md-6 selectContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                            <textarea disabled = {(this.Disabled("openingHours"))? "disabled" : ""} rows="7" name="openingHours" onChange={this.myChangeHandler} className="form-control">{this.state.openingHours}</textarea>
                    </div>
                    </div>
                    </div>

                    <div className="form-group"> 
                    <label className="col-md-6 control-label">{capitalize(lang.happyHour)}</label>
                        <div className="col-md-6 selectContainer">
                        <div className="input-group">
                            <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                            <textarea rows="3" disabled = {(this.Disabled("happyHour"))? "disabled" : ""} name="happyHour" onChange={this.myChangeHandler} className="form-control">{this.state.happyHour}</textarea>
                    </div>
                    </div>
                    </div>

                    {this.state.isPropietario && <div className="form-group col-md-6">
                        <div className="card">
                        <div className="card-header">
                            {capitalize(lang.local_photos)}
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="container row">
                                {
                                this.state.images.map((image) => {  
                                return <div className="col-md-4 col-sm-4 col-xs-6 image">
                                        <label class="file_upload">
                                            <a className="link" href="javascript:void(0)"  onClick={ () => this.removePhoto(image.placeImageID)}>✖</a>
                                        <img className="imagen2" src={baseurl+image.image} class="img_" />
                                        </label>
                                        </div>                      
                                
                                })}
                                </div>
                            </form>
                        </div>
                        </div>
                    </div>
                    }
                
                    <div className="form-group col-md-6">
                        <div className="card">
                        <div className="card-header">
                            {capitalize(lang.add_newphotos)}
                        </div>
                        <div className="card-body">
                            <MultipleImageUploadComponent handleStateChangeLP ={this.handleStateChangeLP }/>
                        </div>
                        </div>
                    </div>


                    <div className="form-group">
                        <label className="col-md-6 control-label"></label>
                        <div className="col-md-6"><button type="submit" className="btn btn-warning" >{lang.submit.toUpperCase()} <span className="glyphicon glyphicon-send"></span></button>
                        </div>
                    </div>
                    
                    </fieldset>
                    </form>
                    </div>
                    }

                    
                    
                    
                </div>
            );
        }
    }
};

export default withRouter(Edit);