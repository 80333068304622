import React, { Component } from "react";
import { urlWS, GetParamsUserAgent } from '../functions/ifelse.js';
import { getLang } from '../functions/ifelse.js';
import queryString from 'query-string'
import { capitalize } from '../functions/ifelse.js';
var lang = null;
var mensaje;
var classCompleted = "alert alert-danger";
var parametros;

export default class LoginAuto extends Component {
    constructor(props){
        super(props);
        
        //aquí debemos de llamar al ws para crear el local y pasar this.state
        var params = this.props.match.params;
        var paramsWS = this.convertToParamas(params);
        const self = this;
                
        (async () => {
            let data = GetParamsUserAgent();
            const rawResponse = await fetch(urlWS+'user/loginauto.php'+data, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Content-Type':'application/x-www-form-urlencoded' //sin esta línea da error
              },
              body: JSON.stringify(paramsWS)
            });
            const response = await rawResponse.json();   
            
            var id = response.id;
            if(parseInt(id) > 0){
                localStorage.setItem('rememberMe', 1);
                localStorage.setItem('user', response.username);
                localStorage.setItem('email', response.email);
                localStorage.setItem('userID', id);
                this.props.action();
                this.props.history.replace("/add/"+params.placeID+"/"+params.name);
            }else{
                /*mensaje = message;
                self.setState({ 
                    loading:false,
                    completed:true
                });*/  
            }            
          })();
    }

    convertToParamas(objeto){ //operations
        return {
            'token':objeto.token
        }
    }

    render() {
            return(
            <div className="loading_center">
                <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-danger" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-info" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-light" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-dark" role="status">
                <span class="sr-only">Loading...</span>
                </div>
            </div>
            );
        
    }
}