import React from 'react'
import '../../css/places.css'
import Beer from './beer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowsAltH} from '@fortawesome/free-solid-svg-icons'
import { Icontypebar } from '../../functions/ifelse';
import { distance } from '../../functions/ifelse';
let lang = null;
class Place extends React.Component{

    constructor(props){
        super(props);
        lang=this.props.lang;
    }
    
    handleclick=()=>{
        this.props.handleClick(this.props.place)
    };

    

    

    render(){
        let typebar = Icontypebar(this.props.place.type);

        const title= this.props.place.name;
        const numRows = this.props.place.beers.length;

        let distancia = null;
        let colDist = "distnone";

        distancia = distance(this.props.loc, this.props.place);
        if(distancia !== null)
            colDist = "col-3";
        

        if(numRows > 0){
            let clase = "placebox";
            let selectedcolor = "#212529";
            if(this.props.selected){
                clase += " placeboxselected";
            }
            return(
                <div className={clase}>
                    <div className="row" onClick={this.handleclick}>
                        <div className="row placehead">
                            <div className="col-2">
                                <button type="button" className="btn btn-ligth"><FontAwesomeIcon icon={typebar} color={selectedcolor}/></button>
                            </div>
                            <div className="place-title col-7">
                                {title}
                            </div>
                            <div className={colDist+" place-dist text-right"}>                                
                                    <FontAwesomeIcon icon={faArrowsAltH}/>
                                    &nbsp;
                                    {distancia}
                                
                            </div>                            
                        </div>
                    </div>    
                    
                    {this.props.place.beers.map((beer) => {
                            return <Beer
                            key={beer.beerID}
                            beer={beer}
                            handleClick={this.props.selectBeer}
                            lang={lang}>
                            </Beer>
                    })}

                </div>
                

                   
            );
        }
        else{
            //Los bares que no tienen cervezas añadidas no los devolvemos.
            return null;
        }
    }
}
export default Place;