import React, { Component } from 'react';

export default class GoogleImage extends Component {

    fileObj = [];
    fileArray = [];

    constructor(props) {
        super(props)
        this.state = {
            file: this.props.fileArray
        }
        this.fileArray = this.props.fileArray;
    }

    uploadMultipleFiles(e) {
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
        }
        this.setState({ file: this.fileArray })
    }


    uploadFiles(e) {
        e.preventDefault()
    }

    render() {
        return (
            
            <form>
                <div className="container row">
                    {(this.fileArray || []).map(url => (
                        
                        <div className="col-md-4 col-sm-4 col-xs-6 image">
                            <label class="file_upload">
                                <a className="link" href="javascript:void(0)" onClick={ () => this.props.handleStateChangeGP(url) }>✖</a>
                            <img className="imagen2" src={url} class="img_" />
                            </label>
                            <input type="hidden" name="images[]" value={url}/>
                        </div>
                        

                    ))}
                </div>
            </form >
        )
    }
}