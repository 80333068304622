import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapPin } from '@fortawesome/free-solid-svg-icons'


class CurrentLocation extends React.Component{
    render(){
        return (
            // si el onclick no tiene parámetros llama directamente a esa prop que debe ser una función en la clase padre
            //handlerMarker es una propiedad más del componente marker
        <div>
            <FontAwesomeIcon icon={faMapPin} color="blue"/>
        </div>

        );
    }
}
export default CurrentLocation;