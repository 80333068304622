import React from 'react'
import '../../css/places.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icontypebar } from '../../functions/ifelse';
import { faStar } from '@fortawesome/free-solid-svg-icons'
class PlaceProfile extends React.Component{

    constructor(props){
        super(props);
    }

    render(){
        let typebar = Icontypebar(this.props.place.type);

            const title= this.props.place.name;

        
            let selectedcolor = "#212529";
            let colorhex = selectedcolor;
            const classbutton = "btn btn-circle btn-dark";
            var rating = this.props.place.gRating;
            return(
                <div className="place" onClick ={this.props.handlerClickPlace}>
                            <div className="row beer">
                                        



                                        <div className="col-2">
                                            <div className="row">
                                                <button type="button" className={classbutton}><FontAwesomeIcon icon={typebar}  color= {selectedcolor}/></button>
                                            </div>
                                        </div>

                                        <div className="col-10">
                                            <div className="row">
                                                {title}
                                            </div>
                                            <div className="row stars">
                                                        <span><FontAwesomeIcon color={rating > 0 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                        <span><FontAwesomeIcon color={rating > 1 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                        <span><FontAwesomeIcon color={rating > 2 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                        <span><FontAwesomeIcon color={rating > 3 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                        <span><FontAwesomeIcon color={rating > 4 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                </div>
                                        </div>  
                            </div>
                    </div>

                   
            );
    }
}
export default PlaceProfile;