import React from "react";
import { Component } from "react";
import '../css/create.css';
import { urlWS } from '../functions/ifelse.js';
import { CheckLogin, getLang , GetParamsUserAgent} from '../functions/ifelse.js';
import {Link} from 'react-router-dom'


let params = null;
var idiomas = require("../functions/idiomas.json");
var beerID, placeName, placeID;
var types_recipient,types_made,types_colors,types_volume;


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

var mensaje = "";
var classCompleted = "alert alert-danger";
var followmessage = "";
var userID,lang;


class EditBeer extends Component {
    
    constructor(props){
        super(props);

        userID = CheckLogin(props);

        this.state ={
            name:'',
            brand:'',
            color:'blond',
            style:'',
            made:'comercial',
            recipient:'draft',
            volume:'25',
            ingredients:'',
            description:'',
            alcoholPercent:'',
            country:'',
            rating:0,
            price:'',
            priceHappyHour:'',
            happyHour:'',
            withoutGluten:0,
            isPropietario:false,
            loading:true,
            completed:false
        }
        params  = this.props.match.params;

        beerID = params.beerID;
        
        lang = getLang();       
        

        types_recipient = lang.beer_recipient;
        types_made = lang.beer_made;
        types_colors = lang.beer_colors;
        types_volume = lang.beer_volume;

        
        
    }

    componentWillMount(){
        var self = this;
        let data = GetParamsUserAgent();
        fetch(urlWS+'beer/read.php'+data+'&id='+beerID)
                .then(res => res.json())
                .then(
                    (beer) => {
                        var isPropietario = false;
                        if(beer.userID == userID){
                            isPropietario = true;
                        }
                        placeName = beer.name;
                        placeID = beer.placeID;

                        self.setState({ 
                            name:beer.name,
                            brand:beer.brand,
                            color:beer.color,
                            style:beer.style,
                            made:beer.made,
                            recipient:beer.recipient,
                            volume:beer.volume,
                            ingredients:beer.ingredients,
                            alcoholPercent:beer.alcoholPercent,
                            country:beer.country,
                            rating:beer.rating,
                            price:beer.price,
                            priceHappyHour:beer.priceHappyHour,
                            withoutGluten:beer.withoutGluten,
                            isPropietario:isPropietario,
                            loading: false});  
                    },
                    (error) => {
                    console.log(error);
                    }
                )
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if(nam == "withoutGluten"){
            var va = -1;
            if(event.target.checked)
                va = 1;
            this.setState({withoutGluten: va});
        }
        else{
            this.setState({[nam]: val});    
        }
        
    }

    myChangeHandlerStyle = (event) => {
        this.setState({style: event.value});
    }


    myClickStar(n) {
        var self= this;
        return function () {
            self.setState({rating:n})
         }
    }

    convertToParamas(objeto){ //operations
        return {
            'name':objeto.name,
            'brand':objeto.brand,
            'color':objeto.color,
            'style':objeto.style,
            'made':objeto.made,
            'recipient':objeto.recipient,
            'volume':objeto.volume,
            'ingredients':objeto.ingredients,
            'alcoholPercent':objeto.alcoholPercent,
            'country':objeto.country,
            'rating':objeto.rating,
            'price':objeto.price,
            'priceHappyHour':objeto.priceHappyHour,
            "beerID":beerID,
            'withoutGluten':objeto.withoutGluten
        }
     }

  
 
     //,    'arrayGooglePhotos':objeto.arrayGooglePhotos,    'arrayLocalPhotos':objeto.arrayLocalPhotos
 
     mySubmitHandler = (event) => {
         event.preventDefault();
         //aquí debemos de llamar al ws para crear el local y pasar this.state
         var params = this.convertToParamas(this.state);
         const self = this;
         self.setState({ 
             loading:true
         });  
         
         (async () => {
            let data = GetParamsUserAgent();
             const rawResponse = await fetch(urlWS+'beer/update.php'+data, {
               method: 'POST',
               headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 'Content-Type':'application/x-www-form-urlencoded' //sin esta línea da error
               },
               body: JSON.stringify(params)
             });
             const response = await rawResponse.json();          
             var id = response.id;
             var message = lang.error;
             if(parseInt(id) > 0){
                mensaje = lang.beer_updated;
                classCompleted = "alert alert-success";
                followmessage = lang.home;
                self.setState({ 
                    loading:false,
                    completed:true
                });  
                 //////////////////////////////////////////////////////FIN LOCAL IMAGES//////////////////////////////////
 
             }else{
                mensaje = lang.error;
                classCompleted = "alert alert-danger";
                followmessage = lang.home;
                 self.setState({ 
                     loading:false,
                     completed:true
                 });  
             }            
           })();
       }

    refreshPage() {
        window.location.reload(false);
    }

    render() {
        if(this.state.loading == true){
            return(
            <div className="loading_center">
                <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-danger" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-info" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-light" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-dark" role="status">
                <span class="sr-only">Loading...</span>
                </div>
            </div>
            );
        }
        else if(this.state.completed == true){
            
            return(
                <div className="loading_center">
                    <div className={classCompleted}>
                        <div>{mensaje}</div>   
                        <Link to={"/place/"+placeID+"/"+beerID}>{lang.home}</Link>
                    </div>
                </div>
                );
        }
        else if(this.state.isPropietario){
        var estilo = this.state.style;
        var htmlstyles = [];
        for(let j = 0 ; j < idiomas.styles_beer2.length ; j++) {
            htmlstyles.push(<option selected={idiomas.styles_beer2[j] == estilo} key={idiomas.styles_beer2[j]} value={idiomas.styles_beer2[j]}>{idiomas.styles_beer2[j]}</option>);
        }

        
        return (
            <div className="container h-100">
                <form className="well form-horizontal" onSubmit={this.mySubmitHandler}  id="contact_form">
                <fieldset>
                
                <div className="form-group col-md-6 margin-top-20"><h3><b>{placeName} - {lang.edit_beer}</b></h3></div>
                
                
                <div className="card">
                    <div className="card-body">
                    <div className="card-title">{lang.required_params}</div>
                    <div className="card-text">
                        <div className="form-group">
                            <label className="col-md-6 control-label">{capitalize(lang.name)}</label>  
                            <div className="col-md-6 inputGroupContainer">
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                                    <input name="name" required placeholder={lang.name_placeholder} value={this.state.name} onChange={this.myChangeHandler} className="form-control"  type="text"/>
                                </div>
                            </div>
                            </div>

                        

                            <div className="form-group">
                            <label className="col-md-6 control-label">{capitalize(lang.price)}</label>  
                            <div className="col-md-6 inputGroupContainer">
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                                    <input required  type="number" placeholder={lang.price_placeholder}  name="price" value={this.state.price} onChange={this.myChangeHandler} className="form-control" pattern="^\d*(\.\d{0,2})?$" step=".01"/>
                                </div>
                            </div>
                            </div>

                            <div className="form-group"> 
                                <label className="col-md-6 control-label">{capitalize(lang.volume)}</label>
                                    <div className="col-md-6 selectContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                                    <select name="volume" className="form-control selectpicker" onChange={this.myChangeHandler}>
                                        {Object.keys(types_volume).map(key => 
                                        <option  selected={key == this.state.volume} key={key} value={key}>{types_volume[key]}</option>                          
                                        )}
                                    </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group"> 
                            <label className="col-md-6 control-label">Color</label>
                                <div className="col-md-6 selectContainer">
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                                    <select name="color" className="form-control selectpicker" onChange={this.myChangeHandler}>
                                        {Object.keys(types_colors).map(key => 
                                        <option  selected={key == this.state.color} key={key} value={key}>{types_colors[key]}</option>                          
                                        )}
                                    </select>
                            </div>
                            </div>
                            </div>

                            

                            <div className="form-group">
                            <label className="col-md-6 control-label">{capitalize(lang.made)}</label>  
                            <div className="col-md-6 inputGroupContainer">
                                <div className="input-group">
                                    <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                                    <select name="made" className="form-control selectpicker" onChange={this.myChangeHandler}>
                                        {Object.keys(types_made).map(key => 
                                        <option selected={key == this.state.made} key={key} value={key}>{types_made[key]}</option>                          
                                        )}
                                    </select>
                                </div>
                            </div>
                            </div>

                            <div className="form-group"> 
                                <label className="col-md-6 control-label">{capitalize(lang.recipient)}</label>
                                    <div className="col-md-6 selectContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                                    <select name="recipient" className="form-control selectpicker" onChange={this.myChangeHandler}>
                                        {Object.keys(types_recipient).map(key => 
                                        <option selected={key == this.state.recipient} key={key} value={key}>{types_recipient[key]}</option>                          
                                        )}
                                    </select>
                                    </div>
                                </div>
                            </div>
                    </div>
                    </div>
                </div>



                <div className="card">
                    <div className="card-body">
                    <div className="card-title">{lang.optional_params}</div>
                    <div className="card-text">
                        
                        <div className="form-group"> 
                        <label className="col-md-6 control-label">{capitalize(lang.estilo)} (<a href="types.html" target="_BLANK">?</a>)</label>
                            <div className="col-md-6 selectContainer">
                            <div className="input-group">
                                <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                                <select name="style" className="form-control selectpicker" onChange={this.myChangeHandler}>
                                {
                                    htmlstyles
                                }
                                </select>
                        </div>
                        </div>
                        </div>

                        

                        

                        <div className="form-group">
                        <label className="col-md-6 control-label">{capitalize(lang.alcoholPercent)}</label>  
                        <div className="col-md-6 inputGroupContainer">
                            <div className="input-group">
                                <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                                <input  type="number" placeholder={lang.alcoholPercent_placeholder}  name="alcoholPercent" value={this.state.alcoholPercent} onChange={this.myChangeHandler} className="form-control" pattern="^\d*(\.\d{0,2})?$" step=".01"/>
                            </div>
                        </div>
                        </div>

                        

                        <div className="form-group">
                        <label className="col-md-6 control-label">{capitalize(lang.priceHappyHour)} (€)</label>  
                        <div className="col-md-6 inputGroupContainer">
                            <div className="input-group">
                                <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                                <input  type="number" placeholder={lang.priceHappyHour_placeholder}  name="priceHappyHour" value={this.state.priceHappyHour} onChange={this.myChangeHandler} className="form-control" pattern="^\d*(\.\d{0,2})?$" step=".01"/>
                            </div>
                        </div>
                        </div>


                        
                        
                        <div className="form-group"> 
                        <label className="col-md-6 control-label">{capitalize(lang.ingredients)}</label>
                            <div className="col-md-6 selectContainer">
                            <div className="input-group">
                                <span className="input-group-addon"><i className="glyphicon glyphicon-list"></i></span>
                                <textarea rows="1" name="ingredients" onChange={this.myChangeHandler} className="form-control">{this.state.ingredients}</textarea>
                        </div>
                        </div>
                        </div>

                        <div className="form-group">
                        <label className="col-md-6 control-label" >{capitalize(lang.marca)}</label> 
                            <div className="col-md-6 inputGroupContainer">
                            <div className="input-group">
                        <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                            <input  name="brand" placeholder={lang.brand_placeholder} value={this.state.brand} onChange={this.myChangeHandler} className="form-control"  type="text"/>
                            </div>
                        </div>
                        </div>

                        <div className="form-group">
                        <label className="col-md-6 control-label" >{capitalize(lang.country)}</label> 
                            <div className="col-md-6 inputGroupContainer">
                            <div className="input-group">
                        <span className="input-group-addon"><i className="glyphicon glyphicon-user"></i></span>
                            <input  name="country" value={this.state.country} onChange={this.myChangeHandler} className="form-control"  type="text"/>
                            </div>
                        </div>
                        </div>

                        <div className="form-group">
                        <label className="col-md-6 control-label" >{capitalize(lang.withoutGluten)}</label> 
                            <div className="col-md-1 inputGroupContainer">
                                <input  name="withoutGluten" checked = {(this.state.withoutGluten == "1")? true : false} value={this.state.withoutGluten} onChange={this.myChangeHandler} className="form-control"  type="checkbox"/>
                            </div>
                        </div>
                            
                    </div>
                </div>
                </div>

                

                

                
                <div className="form-group">
                    <label className="col-md-6 control-label"></label>
                    <div className="col-md-6"><button type="submit" className="btn btn-warning" >{lang.submit.toUpperCase()} <span className="glyphicon glyphicon-send"></span></button>
                    </div>
                </div>
                
                </fieldset>
                </form>
                </div>                
                
                
        );
        }
        else{
            return(
                <div className="loading_center">
                    <div className={classCompleted}>
                        <div>Forbidden</div>                        
                        
                    </div>
                </div>
                );
        }
    }
};

export default EditBeer;
