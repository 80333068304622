import React from 'react'
import '../../css/beers.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBeer, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { faWineBottle } from '@fortawesome/free-solid-svg-icons'
import { faHandPaper } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
let lang = null;

class BeerProfile extends React.Component{

    constructor(props){
        super(props);
        lang=this.props.lang;
    }
    
    render(){
        const title= this.props.beer.name;
        const color= this.props.beer.color;
        const recipient= this.props.beer.recipient;
        const volume= this.props.beer.volume / 100;
        let rating= parseFloat(this.props.beer.rating);
        rating = rating.toFixed(1);
        let price= parseFloat(this.props.beer.price);
       
        if(price >= 10)
            price = price.toFixed(0);
        else 
            price = price.toFixed(1);
        const brand= this.props.beer.brand;
        let imgB = faBeer;
        let handmade = faHandPaper;

        const made = this.props.beer.made;
        if(made == "comercial")
            handmade = faBuilding;

        if(recipient == "bottle"){
            imgB = faWineBottle;
        }
         
        const colorhex = lang.beer_colors_hex[color]
        const classbutton = "btn btn-circle btn-"+color;

        
        
        return(
                        <div className="place" onClick ={this.props.handlerClickBeer}>
                            <div className="row beer">
                                        <div className="col-8">
                                            <div className="row">
                                                {title}
                                            </div>
                                                <div className="row stars">
                                                        <span><FontAwesomeIcon color={rating > 0 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                        <span><FontAwesomeIcon color={rating > 1 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                        <span><FontAwesomeIcon color={rating > 2 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                        <span><FontAwesomeIcon color={rating > 3 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                        <span><FontAwesomeIcon color={rating > 4 ? colorhex : '#bbb'} icon={faStar}/></span>
                                                </div>
                                        </div>  



                                        <div className="col-4">
                                            <div className="row justify-content-end">
                                            <button type="button" className={classbutton}>{price+"€"}</button>
                                                <button type="button" className={classbutton}><FontAwesomeIcon icon={imgB}  color= {colorhex}/></button>
                                                
                                                <button type="button" className={classbutton}><FontAwesomeIcon icon={handmade}   color= {colorhex}/></button>
                                                
                                                <button type="button" className={classbutton}>{volume +"l"}</button>
                                            </div>
                                                    
                                        </div>
                            </div>
                    </div>
        );
    }
}
export default BeerProfile;