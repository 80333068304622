import React from 'react'
import '../../css/beers.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faStar } from '@fortawesome/free-solid-svg-icons'



class Comment extends React.Component{

    constructor(props){
        super(props);
    }
    render(){
        
        const comment = this.props.comment;
        var username = comment.username;
        var colorhex = this.props.color;
        var title = comment.username;
        var rating = comment.rating;
        
        return(
            
                <div className="comment col-4">
                    <div className="commentContent">
                    <div className="row">
                        <div className="starsComment">
                                    <span><FontAwesomeIcon color={rating > 0 ? colorhex : '#bbb'} icon={faStar}/></span>
                                    <span><FontAwesomeIcon color={rating > 1 ? colorhex : '#bbb'} icon={faStar}/></span>
                                    <span><FontAwesomeIcon color={rating > 2 ? colorhex : '#bbb'} icon={faStar}/></span>
                                    <span><FontAwesomeIcon color={rating > 3 ? colorhex : '#bbb'} icon={faStar}/></span>
                                    <span><FontAwesomeIcon color={rating > 4 ? colorhex : '#bbb'} icon={faStar}/></span>
                                    <span className="userComment">{title}</span>
                        </div>
                    </div>
                    <div className="row" style={{fontSize:'9px',color:'#ccc'}}>
                        {comment.created}
                    </div>
                    <div className="row">
                        {comment.comment}
                    </div>
                    </div>
                                     
             </div>
        );
    }
}
export default Comment;