////////////////////////////////////////////////////////////////////////////////////// DEFINE //////////////////////////////////////////////////
/*react / components*/
import React from 'react'
import Modal from 'react-responsive-modal'
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';

/*styles*/
import 'react-awesome-slider/dist/styles.css';
import '../css/modal.css'


/*functions*/
import { baseurl } from '../functions/ifelse.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserCircle,faSignOutAlt, faBeer} from '@fortawesome/free-solid-svg-icons'

////////////////////////////////////////////////////////////////////////////////////// FIN DEFINE //////////////////////////////////////////////////
let bg = {
    overlay: {
      //lo que queda feura del modal
    },
    modal:{
        background: "rgb(245, 247, 249)",
        width:'100%'
    },
    closeButton:{
        width:'20px',
        top:'5px',
        rigth:'1px'
    }
  };
let lang = null;
let id = 0;
let user,userID;
class ModalUser extends React.Component {
    
    constructor(props){
        super(props);
        lang=this.props.lang;
    }

    

    updateParent(){
        this.props.toggleShowModalCallback()
    }


    

  

    render(){      
        user = localStorage.getItem('user') ;
        userID = localStorage.getItem('userID') ;

        if(user != null && userID != null){
            console.log("render user");
            //puede que no haya beer seleccionada
           console.log(user);
           console.log(lang);
            
           
            return (
                
                <Modal open={this.props.abierto} onClose={this.updateParent.bind(this)} center styles={bg}>
                    <div className="container">
                        <div className="row profile">
                            <div className="col-12 profile-sidebar">
                                    <div className="row profile-userpic">
                                        <div className="col-12"><span><FontAwesomeIcon size="6x" icon={faUserCircle}/></span></div>
                                    </div>
                                    <div className="row profile-usertitle">
                                        <div className="profile-usertitle-name col-12">
                                        {user}
                                        </div>
                                    </div>
                                    <div className="row profile-userbuttons">
                                            <div className="col-lg-4 col-12"><Link to={"/profile/"+userID} onClick={this.props.close} ><button type="button" className="btn btn-info btn-sm">{lang.profile}<FontAwesomeIcon style={{marginLeft:'5px'}}  icon={faUserCircle}/></button></Link></div>
                                            <div className="col-lg-4 col-12"><Link to={"/profile/"+userID} onClick={this.props.close} ><button type="button" className="btn btn-warning btn-sm">{lang.your_beers}<FontAwesomeIcon style={{marginLeft:'5px'}}  icon={faBeer}/></button></Link></div>
                                            <div className="col-lg-4 col-12 "><Link to={"/profile/"+userID} onClick={this.props.close} ><button type="button" onClick ={this.props.handlerLogout} className="btn btn-secondary btn-sm">{lang.logout}<FontAwesomeIcon style={{marginLeft:'5px'}}  icon={faSignOutAlt}/></button></Link></div>
                                    </div>
                                    <div className="profile-usermenu row"> 
                                            <div className="col-lg-6 col-12"><span  >Worldbeermap &copy; 2020</span></div>
                                            <div className="col-lg-6 col-12"><a href="mailto:info@worldbeermap.com">info@worldbeermap.com</a></div>
                                    </div>
                            </div>
                        </div>
                    </div>              
                </Modal>
            );

                }else{
                    return null;
                }
     }
}
 
export default withRouter(ModalUser);