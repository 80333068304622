import React, { Component } from "react";
import {withRouter} from 'react-router-dom'
import { urlWS, GetParamsUserAgent } from '../functions/ifelse.js';
import { getLang } from '../functions/ifelse.js';
import queryString from 'query-string'
import { capitalize } from '../functions/ifelse.js';
var lang = null;
var mensaje;
var classCompleted = "alert alert-danger";
var parametros;

class Login extends Component {
    constructor(props){
        super(props);
        this.state ={
            email:null,
            password:null,
            loading:false,
            completed:false
        }

        parametros = queryString.parse(this.props.location.search);
        if(window.cordova)
            parametros.href = "";

        if(parametros.href == undefined)
            parametros.href = "";
    }

    convertToParamas(objeto){ //operations
        return {
            'email':objeto.email,
            'password':objeto.password
        }
    }

    componentWillMount(){
        lang = getLang();
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({[nam]: val});   
      }

      mySubmitHandler = (event) => {
        event.preventDefault();


        //aquí debemos de llamar al ws para crear el local y pasar this.state
        var params = this.convertToParamas(this.state);
        const self = this;
        self.setState({ 
            loading:true
        });  
        
        (async () => {
            let data = GetParamsUserAgent();
            const rawResponse = await fetch(urlWS+'user/login.php'+data, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Content-Type':'application/x-www-form-urlencoded' //sin esta línea da error
              },
              body: JSON.stringify(params)
            });
            const response = await rawResponse.json();          
            var id = response.id;
            var message = lang.error;
            if(parseInt(id) > 0){
                //login correcto.
                self.setState({ 
                    loading:false,
                    completed:true
                });  
                localStorage.setItem('rememberMe', self.state.remember);
                localStorage.setItem('user', response.username);
                localStorage.setItem('email', self.state.email);
                localStorage.setItem('userID', id);
                this.props.action();
                this.props.history.replace(parametros.href);
            }else{
                mensaje = message;
                self.setState({ 
                    loading:false,
                    completed:true
                });  
            }            
          })();
      }

    render() {
        if(this.state.loading == true){
            return(
            <div className="loading_center">
                <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-danger" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-info" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-light" role="status">
                <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-dark" role="status">
                <span class="sr-only">Loading...</span>
                </div>
            </div>
            );
        }
        else{
        return (
            <div className="auth-wrapper">

            
            
                <div className="auth-inner col-12 col-lg-4">
                    {this.state.completed === true &&
                            <div className={classCompleted}>
                                <div>{capitalize(lang.incorrect_user)}</div>                        
                                <a align="center" href="/login" class="alert-link">{}</a>
                            </div>
                    }
                    <form  onSubmit={this.mySubmitHandler}>
                        <h3>Login</h3>
                        <p className="forgot-password text-right">
                             <a href="./signup">{capitalize(lang.notaccount)}</a>
                        </p>
                        <div className="form-group">
                            <label>{capitalize(lang.email)}</label>
                            <input onChange={this.myChangeHandler} name="email" type="email" className="form-control" />
                        </div>

                        <div className="form-group">
                            <label>{capitalize(lang.password)}</label>
                            <input name="password" onChange={this.myChangeHandler}  type="password" className="form-control" />
                        </div>

                        <button type="submit" className="btn btn-primary btn-block">{capitalize(lang.submit)}</button>
                        <p className="forgot-password text-right">
                             <a href="#">{capitalize(lang.forgot)}</a>
                        </p>
                    </form>
            </div>
            </div>
        );
        }
    }
}

export default withRouter(Login);