import React, { Component } from 'react';

export default class MultipleImageUploadComponent extends Component {

    fileObj = [];
    fileArray = [];

    constructor(props) {
        super(props)
        this.state = {
            file: [null]
        }
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
    }

    uploadMultipleFiles(e) {
        console.log(e.target.files);
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(this.fileObj[0][i])
            console.log(URL.createObjectURL(this.fileObj[0][i]));
        }
        this.setState({ file: this.fileArray })
        this.props.handleStateChangeLP(this.fileArray);
    }

    removeFile(file) {
        var index = this.fileArray.indexOf(file); // Let's say it's Bob.
        delete  this.fileArray[index];
        this.setState({ file: this.fileArray })
        this.props.handleStateChangeLP(this.fileArray);
    }

    uploadFiles(e) {
        e.preventDefault()
        console.log(this.state.file)
    }

    render() {
        return (
            <form>
                <div className="container row">
                    {(this.fileArray || []).map(file => (
                        
                        
                        <div className="col-md-4 col-sm-4 col-xs-6 image">
                            <label class="file_upload">
                                <a className="link" href="javascript:void(0)" onClick={ () => this.removeFile(file) }>✖</a>
                            <img className="imagen2" src={URL.createObjectURL(file)} class="img_" />
                            </label>
                            <input type="hidden" name="images[]" value={file}/>
                        </div>
                        

                    ))}
                </div>
                <div className="form-group">
                    <input name="photos[]" type="file" className="form-control" onChange={this.uploadMultipleFiles} multiple />
                </div>
            </form >
        )
    }
}